import moment from "moment";
import { TTopicDetails } from "../../../quan-ly-de-tai/de-tai/models/TopicModel";

const currentYear = moment().year();

export type TAcceptanceCouncil = {
  createdDate?: string | null;
  createdBy?: string | null;
  modifiedDate?: string | null;
  modifiedBy?: string | null;
  id?: string | null;
  orgId?: string | null;
  regisPeriodId?: string | null;
  name?: string | null;
  time?: string | null;
  year?: string | null;
  date?: string | null;
  place?: string | null;
  numMembers?: number | null;
  numTopics?: number | null;
  typeId?: number | null;
  typeName?: string | null;
  statusId?: number | null;
  statusName?: string | null;
  dateTime?: string | null;
  members?: TAcceptanceCouncilMember[];
  topics?: TAcceptanceCouncilTopic[];
};

export type TAcceptanceCouncilFilter = {
  year?: string;
  yearOption?: any;
  regisPeriodId?: string;
  keyword?: string;
  regisPeriod?: any;
  typeId?: number;
  pageIndex?: number;
  pageSize?: number;
};

export const initFilter: TAcceptanceCouncilFilter = {
  year: currentYear.toString(),
  regisPeriodId: "",
  keyword: "",
  typeId: 2,
  pageIndex: 1,
  pageSize: 10,
};

export type TAcceptanceCouncilMember = {
  id?: string;
  name?: string;
  gender?: number;
  genderOption?: any;
  duty?: string;
  academicRank?: string;
  degree?: string;
  department?: string;
  position?: string;
  email?: string;
  phone?: string;
  office?: string;
};

export const initAcceptanceCouncilMember: TAcceptanceCouncilMember = {
  name: "",
  gender: undefined,
  duty: "",
  academicRank: "",
  degree: "",
  department: "",
  position: "",
  email: "",
  phone: "",
  office: "",
};

export type TAcceptanceCouncilTopic = {
  id?: string;
  name?: string;
  code?: string;
  statusName?: string;
};

export type TAddAcceptanceCouncil = {
  year?: string;
  regisPeriodId?: string;
  regisPeriod?: any;
  name?: string;
  time?: string;
  date?: string;
  place?: string;
  typeId?: number;
  file?: string;
  originalFileName?: string;
  numMembers?: number;
  numTopics?: number;
  members?: TAcceptanceCouncilMember[];
  topics?: TAcceptanceCouncilTopic[];
};

export const initAddAcceptanceCouncil: TAddAcceptanceCouncil = {
  year: "",
  regisPeriodId: "",
  name: "",
  time: "",
  date: "",
  place: "",
  typeId: 2,
  file: "",
  numMembers: 5,
  numTopics: 0,
  members: Array.from(
    { length: 5 },
    () => (initAcceptanceCouncilMember)
  ),
  topics: []
}

export type TSummaryInfo = {
  numPendingInspection?: number;
  numUnderInspection?: number;
};

export type TAcceptanceCouncilDetails = {
  createdDate?: string;
  createdBy?: string;
  modifiedDate?: string;
  modifiedBy?: string;
  id?: string;
  orgId?: string;
  regisPeriodId?: string;
  regisPeriod?: string;
  name?: string;
  time?: string;
  year?: string;
  date?: string;
  place?: string;
  numMembers?: number;
  numTopics?: number;
  typeId?: number;
  typeName?: string;
  statusId?: number;
  statusName?: string;
  dateTime?: string;
  members?: TCouncilDetailsMember[];
  topics?: TTopicDetails[];
  originalFileName?: string;
  fileId?: string;
};

export type TCouncilDetailsMember = {
  createdDate?: string;
  createdBy?: string;
  modifiedDate?: string;
  modifiedBy?: string;
  id?: string;
  orgId?: string;
  committeeId?: string;
  name?: string;
  phone?: string;
  email?: string;
  department?: string;
  position?: string;
  gender?: number;
  academicRank?: string;
  degree?: string;
  duty?: string;
  office?: string;
};
