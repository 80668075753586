import { FieldArray, FormikErrors, FormikHandlers } from "formik";
import { FC } from "react";
import { Col, Row } from "react-bootstrap";
import Select, { SingleValue } from 'react-select';
import { toast } from "react-toastify";
import { TextFieldCustom } from "../../../../category-personnel/components/text-field-custom/TextFieldCustom";
import { GENDER } from "../../../../utils/Constant";
import { listAcceptanceCouncilMember } from "../../constants/constants";
import { initAcceptanceCouncilMember, TAcceptanceCouncilDetails, TAcceptanceCouncilMember, TAddAcceptanceCouncil } from "../../models/AcceptanceCouncilManagerModels";
import AutocompleteObjectV2 from '../../../../component/AutocompleteObjectV2';

type Iprops = {
  values: TAddAcceptanceCouncil;
  errors: FormikErrors<TAddAcceptanceCouncil>;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  isView?: boolean;
  details?: TAcceptanceCouncilDetails;
};

const Step2: FC<Iprops> = (props) => {
  const { values, errors, handleChange, setFieldValue, isView, details } = props;

  return (
    <div className="w-100">
      <Row className="py-2">
        <Col xs={12}>
          <div className="flex align-items-center">
            <div className="label min-w-150px fw-bold">Số lượng thành viên</div>
            <div className="flex-1">
              <TextFieldCustom
                type="number"
                title=""
                name="numMembers"
                disabled
                values={values.numMembers || details?.numMembers}
                errors={errors.numMembers}
                handleChange={(e: React.ChangeEvent<any>) => {
                  let quantity = Number(e?.target?.value);
                  if (quantity > 5) {
                    toast.warning("Không được quá 5 thành viên");
                    return;
                  }
                  let newMemberList: TAcceptanceCouncilMember[] = Array.from(
                    { length: Number(e?.target?.value) },
                    () => (initAcceptanceCouncilMember)
                  );
                  setFieldValue("members", newMemberList);
                  handleChange(e);
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="py-2">
        <FieldArray
          name="members"
          render={() => (
            <>
              {(values?.members || details?.members)?.map((item: TAcceptanceCouncilMember, index: number) => (
                <div
                  className={`member py-2 border`}
                  key={index}
                >
                  <Row>
                    <Col xs={12}>
                      <div className="label min-w-150px fw-bold text-pri spaces pl-16 py-8">
                        Thành viên {index + 1}
                      </div>
                      <div className="flex align-items-center pb-4">
                        <div className="label min-w-150px fw-bold spaces pl-16">
                          Họ và tên
                        </div>
                        <div className="flex-1">
                          {isView ? (
                            <TextFieldCustom
                              type="text"
                              title=""
                              name={`members.${index}.name`}
                              disabled={isView}
                              values={values?.members?.[index]?.name || details?.members?.[index]?.name}
                              errors={""}
                              handleChange={handleChange}
                            />
                          ): (
                            <Select
                              name={`member${index + 1}`}
                              value={values?.members?.[index]}
                              onChange={(option: SingleValue<TAcceptanceCouncilMember>) => {
                                delete option?.id
                                setFieldValue(`members.${index}`, option);
                              }}
                              getOptionLabel={(option) => option?.name || ""}
                              getOptionValue={(option) => option?.id || ""}
                              options={listAcceptanceCouncilMember}
                            />
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} className="pb-4">
                      <div className="flex align-items-center">
                        <div className="label min-w-150px fw-bold spaces pl-16">
                          Giới tính
                        </div>
                        <div className="flex-1">
                          <AutocompleteObjectV2
                            options={GENDER}
                            isSearchDefauilt={true}
                            name={"genderOption"}
                            isDisabled={isView}
                            onChange={(e: any) =>  {
                              setFieldValue("gender", e?.value || null);
                              setFieldValue("genderOption", e);
                            }}
                            value={values?.members?.[index]?.genderOption || GENDER.find((item: any) => item?.value === values?.members?.[index]?.gender) || GENDER.find((item: any) => item?.value === details?.members?.[index]?.gender)}
                            errors={""}
                            searchObject={{}}
                            getOptionLabel={(e: any) => e?.title}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} className="pb-4">
                      <div className="flex align-items-center">
                        <div className="label min-w-150px fw-bold spaces pl-16">
                          Vai trò
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name={`members.${index}.duty`}
                            disabled={isView}
                            values={values?.members?.[index]?.duty || details?.members?.[index]?.duty}
                            errors={""}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} className="pb-4">
                      <div className="flex align-items-center">
                        <div className="label min-w-150px fw-bold spaces pl-16">
                          Học hàm
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name={`members.${index}.academicRank`}
                            disabled={isView}
                            values={values?.members?.[index]?.academicRank || details?.members?.[index]?.academicRank}
                            errors={""}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} className="pb-4">
                      <div className="flex align-items-center">
                        <div className="label min-w-150px fw-bold spaces pl-16">
                          Học vị
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name={`members.${index}.degree`}
                            disabled={isView}
                            values={values?.members?.[index]?.degree || details?.members?.[index]?.degree}
                            errors={""}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} className="pb-4">
                      <div className="flex align-items-center">
                        <div className="label min-w-150px fw-bold spaces pl-16">
                          Khoa/Phòng
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name={`members.${index}.department`}
                            disabled={isView}
                            values={values?.members?.[index]?.department || details?.members?.[index]?.department}
                            errors={""}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} className="pb-4">
                      <div className="flex align-items-center">
                        <div className="label min-w-150px fw-bold spaces pl-16">
                          Chức vụ
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name={`members.${index}.position`}
                            disabled={isView}
                            values={values?.members?.[index]?.position || details?.members?.[index]?.position}
                            errors={""}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} className="pb-4">
                      <div className="flex align-items-center">
                        <div className="label min-w-150px fw-bold spaces pl-16">
                          Email
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name={`members.${index}.email`}
                            disabled={isView}
                            values={values?.members?.[index]?.email || details?.members?.[index]?.email}
                            errors={""}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} className="pb-4">
                      <div className="flex align-items-center">
                        <div className="label min-w-150px fw-bold spaces pl-16">
                          SĐT
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name={`members.${index}.phone`}
                            disabled={isView}
                            values={values?.members?.[index]?.phone || details?.members?.[index]?.phone}
                            errors={""}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} className="pb-4">
                      <div className="flex align-items-center">
                        <div className="label min-w-150px fw-bold spaces pl-16">
                          Đơn vị công tác
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name={`members.${index}.office`}
                            disabled={isView}
                            values={values?.members?.[index]?.office || details?.members?.[index]?.office}
                            errors={""}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              ))}
            </>
          )}
        />
      </Row>
    </div>
  );
};

export { Step2 };
