// Đề tài
export type TMyTopic = {
  createdDate?: string;
  createdBy?: string;
  modifiedDate?: string;
  modifiedBy?: string;
  id?: string;
  orgId?: string;
  regisPeriodId?: string;
  name?: string;
  code?: string;
  numMembers?: number;
  numTeachers?: number;
  urgency?: string;
  objective?: string;
  content?: string;
  estimatedTimeframe?: string;
  estimatedBudget?: string;
  expectedOutcome?: string;
  feasibility?: string;
  reference?: string;
  otherInformation?: string;
  statusId?: string;
  statusName?: string;
  stageId?: string;
  stageName?: string;
  ownerId?: string;
  numMonths?: string;
  startDate?: string;
  endDate?: string;
  manageLevel?: string;
  budgetTotal?: string;
  budgetFromGovernment?: string;
  budgetFromOther?: string;
  paymentEntire?: string;
  isPaymentEntire?: boolean;
  isPaymentPart?: boolean;
  paymentPieceWork?: string;
  paymentNotPieceWork?: string;
  isPartProgram?: boolean;
  programName?: string;
  programCode?: string;
  isScience?: boolean;
  isSingle?: boolean;
  isOther?: boolean;
  members?: any[];
  offices?: string
}

// Tìm kiếm
export type TMyTopicFilter = {
  year?: string;
  yearOption?: any;
  regisPeriodId?: string;
  regisPeriod?: any;
  statusId?: string;
  keyword?: string;
  statusOption?: any;
  pageIndex?: number;
  pageSize?: number;
};

export const initFilter: TMyTopicFilter = {
  year: "2024",
  regisPeriodId: "",
  statusId: "",
  keyword: "",
  pageIndex: 1,
  pageSize: 10
};

// Chủ nhiệm đề tài/Nghiên cứu viên chính
export type TMainResearcher = {
  fakeId?: string;
  type?: number;
  dob?: string;
  gender?: number;
  eduLevel?: string;
  scientificTitle?: string;
  office?: string;
  officeCurrently?: string;
  officeAddress?: string;
  fax?: string;
  name?: string;
  position?: string;
  department?: string;
  email?: string;
  phone?: string;
}

// Thư ký đề tài
export type TTopicSecretary = {
  type?: number;
  gender?: number;
  eduLevel?: string;
  scientificTitle?: string;
  office?: string;
  officeCurrently?: string;
  officeAddress?: string;
  fax?: string;
  name?: string;
  position?: string;
  email?: string;
  phone?: string;
}

// Tổ chức chủ trì
export type TExecutiveOrganization = {
  topicId?: string;
  name?: string;
  phone?: string;
  fax?: string;
  website?: string;
  address?: string;
  chiefName?: string; // Họ và tên thủ trưởng
  bankNumber?: string;
  bankName?: string;
  topicSupervisor?: string; // Tên cơ quan chủ quản
  typeId?: number;
}

// Tổ chức phối hợp
export type TMainCoordinatingOrganization = {
  fakeId?: string;
  createdDate?: string;
  createdBy?: string;
  modifiedDate?: string;
  modifiedBy?: string;
  id?: string;
  orgId?: string;
  topicId?: string;
  name?: string;
  phone?: string;
  fax?: string;
  website?: string;
  address?: string;
  chiefName?: string;
  bankName?: string;
  bankNumber?: string;
  topicSupervisor?: string;
  typeId?: number;
  typeName?: string;
}

export const initOrganization: TMainCoordinatingOrganization = {
  topicSupervisor: "",
  phone: "",
  fax: "",
  address: "",
  chiefName: "",
  bankNumber: "",
  bankName: ""
}

// Cán bộ thực hiện
export type TImplementOfficer = {
  id?: string;
  fakeId?: string;
  typeId?: number;
  name?: string; // Họ và tên
  academicRank?: string // Học hàm
  degree?: string // Học vị
  scientificTitle?: string; // Chức danh thực hiên đề tài
  officeCurrently?: string; // Tổ chức công tác
}

export const initImplementOfficer: TImplementOfficer = {
  name: "",
  academicRank: "",
  degree: "",
  scientificTitle: "",
  officeCurrently: ""
}

// Đề cương
export type TTopicOutline = {
  id?: string;
  name?: string;
  code?: string;
  numMonths?: number;
  startDate?: string;
  endDate?: string;
  manageLevel?: string;
  budgetTotal?: number;
  budgetFromGovernment?: number;
  budgetFromOther?: number;
  isPaymentEntire?: boolean;
  isPaymentPart?: boolean;
  paymentPieceWork?: number;
  paymentNotPieceWork?: number;
  isPartProgram?: boolean;
  programName?: string;
  programCode?: string;
  isScience?: boolean;
  isSingle?: boolean;
  isOther?: boolean;
  mainResearcher?: TMainResearcher;
  topicSecretary?: TTopicSecretary;
  executiveOrganization?: TExecutiveOrganization;
  mainCoordinatingOrganization?: TMainCoordinatingOrganization[];
  implementOfficer?: TImplementOfficer[];
}

export const initTopicOutline: TTopicOutline = {
  name: "",
  code: "",
  startDate: "",
  endDate: "",
  manageLevel: "",
  budgetFromGovernment: 0,
  budgetFromOther: 0,
  isPaymentEntire: false,
  isPaymentPart: false,
  paymentPieceWork: 0,
  paymentNotPieceWork: 0,
  isPartProgram: false,
  isScience: false,
  isSingle: false,
  isOther: false,
  mainResearcher: {
    name: "",
    gender: undefined,
    eduLevel: "",
    scientificTitle: "",
    position: "",
    office: "",
    phone: "",
    fax: "",
    email: "",
    officeCurrently: "",
    officeAddress: ""
  },
  topicSecretary: {
    name: "",
    gender: undefined,
    eduLevel: "",
    scientificTitle: "",
    position: "",
    office: "",
    phone: "",
    fax: "",
    email: "",
    officeCurrently: "",
    officeAddress: ""
  },
  executiveOrganization: {
    name: "",
    phone: "",
    fax: "",
    website: "",
    address: "",
    chiefName: "",
    bankNumber: "",
    bankName: "",
    topicSupervisor: "",
  },
  mainCoordinatingOrganization: [],
  implementOfficer: []
};

export type TFile = {
  createdDate?: string | null;
  createdBy?: string | null;
  modifiedDate?: string | null;
  modifiedBy?: string | null;
  id?: string | null;
  fileId?: string | null;
  orgId?: string | null;
  topicId?: string | null;
  name?: string | null;
  originalFileName?: string | null;
  originalName?: string | null;
  extension?: string | null;
  description?: string | null;
  path?: string | null;
  size?: string | null;
  contentType?: string | null;
  ownerId?: string | null;
  typeId?: string | null;
  typeName?: string | null;
  subTypeId?: number | null;
  subTypeName?: string | null;
}

export type TFileProduct = {
  createdDate?: string;
  createdBy?: string;
  modifiedDate?: string;
  modifiedBy?: string;
  id?: string;
  orgId?: string;
  topicId?: string;
  fileId?: string;
  originalFileName?: string;
  content?: string;
  date?: string
}