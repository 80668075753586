import { FC, useContext, useEffect, useRef, useState } from "react";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { AppContext } from "../../appContext/AppContext";
import { TMember } from "../../dang-ky-de-tai/models/TopicRegistrationModel";
import { TTopicDetails } from "../../quan-ly-de-tai/de-tai/models/TopicModel";
import { CODE, MEMBER_TYPE, ORG_TYPE, TRANG_THAI } from "../../utils/Constant";
import { TABS } from "../constants/constants";
import { TExecutiveOrganization, TMainCoordinatingOrganization, TMainResearcher, TMyTopic, TTopicSecretary } from "../models/MyTopicModel";
import { getTopicById } from "../services/services";
import TabDetails from "./tabs/TabDetails";
import TabOutline from "./tabs/TabOutline";
import TabProposal from "./tabs/TabProposal";
import TabMinutes from "./tabs/TabMinutes";

const MyTopicDetails: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setIsLoading } = useContext(AppContext);
  const state = location.state  as { show?: boolean, data?: TMyTopic, tabIndex?: string };

  const formProposalRef = useRef<any>();
  const formOutlineRef = useRef<any>();

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [key, setKey] = useState<string>(state?.tabIndex || TABS.DETAILS.eventKey);
  const [topicInfo, setTopicInfo] = useState<TTopicDetails>({});
  const [mainResearcher, setMainResearcher] = useState<TMainResearcher>({});
  const [topicSecretary, setTopicSecretary] = useState<TTopicSecretary>({});
  const [teacherList, setTeacherList] = useState<TMainResearcher[]>([]);
  const [memberList, setMemberList] = useState<TMainResearcher[]>([]);
  const [executiveOrganization, setExecutiveOrganization] = useState<TExecutiveOrganization>({});
  const [mainCoordinatingOrganizationList, setMainCoordinatingOrganizationList] = useState<TMainCoordinatingOrganization[]>([]);

  useEffect(() => {
    let topicId = state?.data?.id;
    if (!topicId) return;
    getTopicDetails(topicId);
  }, [state?.data?.id]);

  const getTopicDetails = async (topicId: string) => {
    try {
      setIsLoading(true);
      let { data } = await getTopicById(topicId);
      if (data?.code === CODE.SUCCESS) {
        setTopicInfo(data?.data);
        setMainResearcher(
          data?.data?.members?.find(
            (member: TMember) => member?.typeId === MEMBER_TYPE.CHU_NHIEM_DE_TAI
          ) as TMainResearcher
        );
        setTopicSecretary(
          data?.data?.members?.find(
            (member: TMember) => member?.typeId === MEMBER_TYPE.THU_KY
          ) as TTopicSecretary
        );
        setTeacherList(
          data?.data?.members?.filter(
            (member: TMember) => member?.typeId === MEMBER_TYPE.GVCN
          ) as TMember[]
        );
        setMemberList(
          data?.data?.members?.filter(
            (member: TMember) => member?.typeId === MEMBER_TYPE.CAN_BO_THUC_HIEN
          ) as TMember[]
        );
        setExecutiveOrganization(
          data?.data?.offices?.find(
            (organization: TExecutiveOrganization) => organization?.typeId === ORG_TYPE.TO_CHUC_CHU_TRI
          ) as TExecutiveOrganization
        );
        setMainCoordinatingOrganizationList(
          data?.data?.offices?.filter(
            (organization: TMainCoordinatingOrganization) => organization?.typeId === ORG_TYPE.TO_CHUC_PHOI_HOP
          )
        );
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const isShowSubmitBtnFunc = () => {
    let isShow = false;
    switch (key) {
      case TABS.DETAILS.eventKey:
        isShow = false;
        break;
      case TABS.PROPOSAL.eventKey:
        isShow = !!topicInfo.hasSuggestion;
        break;
      case TABS.OUTLINE.eventKey:
        isShow = !!topicInfo.hasModificationRequest;
        break;
      default:
        break;
    }
    return isShow;
  }
  const handleSubmit = () => {
    if(!isEdit) {
      setIsEdit(true);
      return;
    }
    switch (key) {
      case TABS.PROPOSAL.eventKey:
        if (formProposalRef.current) {
          formProposalRef.current.handleSubmit();
        }
        break;
      case TABS.OUTLINE.eventKey:
        if (formOutlineRef.current) {
          formOutlineRef.current.handleSubmit();
        }
        break;
      default:
        break;
    }
  }

  return (
    <Modal
      centered
      show={state?.show}
      onHide={() => navigate("/de-tai-cua-toi", { replace: true })}
      size="xl"
      fullscreen
      className="page-full spaces h-calc-vh-50"
    >
      <Modal.Header className="header-modal min-h-40px">
        <div className="flex justify-content-between w-100 align-items-center">
          <div className="d-flex gap-6 py-2">
            <div
              className="d-flex align-items-center title-back gap-3 cursor-pointer"
              onClick={() => {
                navigate("/de-tai-cua-toi", { replace: true });
              }}
            >
              <i className="spaces bi bi-chevron-left ml-10"> </i>
              <div className="spaces mr-20">Quay lại</div>
            </div>
            <h4 className="text-title border-bottom text-pri fw-bold fs-4 spaces mb-0 px-10">
              Chi tiết đề tài
            </h4>
          </div>
          {isShowSubmitBtnFunc() && (
            <div>
              <button
                type="submit"
                className="spaces min-w-90px btn btn-primary btn btn-primary"
                onClick={handleSubmit}
              >
                {isEdit ? "Lưu" : "Chỉnh sửa"}
              </button>
            </div>
          )}
        </div>
      </Modal.Header>
      <Modal.Body className="spaces p-0 mt-3 bg-white topic-details">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => {
            setIsEdit(false);
            setKey(k || "");
          }}
          className="mb-3 tab-topic-details"
        >
          <Tab eventKey={TABS.DETAILS.eventKey} title={TABS.DETAILS.title}>
            <TabDetails 
              topicInfo={topicInfo} 
              mainResearcher={mainResearcher}
              memberList={memberList}
              teacherList={teacherList}
            />
          </Tab>
          <Tab eventKey={TABS.PROPOSAL.eventKey} title={TABS.PROPOSAL.title}>
            <TabProposal 
              memberList={memberList}
              topicInfo={topicInfo}
              mainResearcher={mainResearcher}
              topicSecretary={topicSecretary}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              formRef={formProposalRef}
            />
          </Tab>
          <Tab eventKey={TABS.OUTLINE.eventKey} title={TABS.OUTLINE.title}>
            <TabOutline 
              topicInfo={topicInfo}
              executiveOrganization={executiveOrganization}
              mainResearcher={mainResearcher}
              topicSecretary={topicSecretary}
              implementOfficerList={memberList}
              mainCoordinatingOrganizationList={mainCoordinatingOrganizationList}
              formRef={formOutlineRef}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
            />
          </Tab>
          {(topicInfo?.statusId === TRANG_THAI.CHO_XET_DUYET && topicInfo?.alreadyExamined) && (
            <Tab eventKey={TABS.SCIENCE_MINUTES.eventKey} title={TABS.SCIENCE_MINUTES.title}>
              <TabMinutes
                currentTab={key}
                topicId={topicInfo?.id}
              />
            </Tab>
          )}
          {(topicInfo?.statusId === TRANG_THAI.CHO_NGHIEM_THU && topicInfo?.alreadyInspected) && (
            <Tab eventKey={TABS.ACCEPTANCE_MINUTES.eventKey} title={TABS.ACCEPTANCE_MINUTES.title}>
              <TabMinutes
                currentTab={key}
                topicId={topicInfo?.id}
              />
            </Tab>
          )}
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};
export default MyTopicDetails;
