import { KEY_LOCALSTORAGE } from "../auth/core/_consts";
import { localStorageItem } from "./LocalStorage";

export const KEY_DS_TAB_TIEP_NHAN = "DSTIEPNHAN";
export const KEY_TAB_CHI_DINH_MAU = "CHIDINHMAU";
export const KEY_DS_TAB_HEN_KHAM = "DSHenKham";
export const KEY_DS_TAB_BENH_AN_DIEN_TU = "DSBENHANDIENTU";
export const KEY_DS_TAB_TIEP_DON = "DSTIEPDON";
export const KEY_DS_TAB_THANH_TOAN = "THANHTOAN"
export const KEY_DS_TAB_XET_NGHIEM = "XET_NGHIEM"
export const DS_NOI_TRU_TIEP_DON = "DS_NOI_TRU_TIEP_DON";
export const DS_NOI_TRU_PHONG_BENH = "DS_NOI_TRU_PHONG_BENH";
export const KEY_DS_TAB_PHAU_THUAT_THU_THUAT = "KEY_DS_TAB_PHAU_THUAT_THU_THUAT"
export const KEY_DS_TAB_CDHA_TDCN = "DS_CDHA_VA_TDCN";
export const DM_TAI_KHOAN = "DM_TAI_KHOAN"
export const DM_KHOA_PHONG = "DM_KHOA_PHONG"
export const DM_NHAN_VIEN = "DM_NHAN_VIEN"
export const DM_DICH_VU_GIA = "DM_DICH_VU_GIA"
export const DM_THIET_BI_CLS = "DM_THIET_BI_CLS"
export const DM_CO_SO_KHAM_CHUA_BENH = "DM_CO_SO_KHAM_CHUA_BENH"
export const DM_GIUONG_BENH = "DM_GIUONG_BENH"
export const KEY_DS_TAB_CHI_TIET_DV_DS_PHIEU = "CHITIETDV_DSPHIEU";
export const KEY_DS_TAB_BENH_AN = "KEY_DS_TAB_BENH_AN";
export const KEY_DS_DANH_MUC_TIEP_DON = "KEY_DS_DANH_MUC_TIEP_DON";
export const TOKEN = "TOKEN";
export const MAX_SIZE = 9999999;

export const REGEX = {
  TEN: /^[^~`!@#$%^&*()+=\-[\]\\';,/{}|\\":<>?\d]+$/,
  AZ_09: /^[a-zA-Z0-9]*$/,
  CHARACTER20: /^.{6,20}$/,
  CHARACTER9or12: /^\d{9}(\d{3})?$/,
  CHARACTER50: /^.{1,50}$/,
  CHARACTER255: /^.{1,255}$/,
  CHECK_PHONE: /^(0|\+84)\d{9,10}$/,
  YEAR: /^.{4,5}$/,
}
export const NUMBER_EXCEPT_THIS_SYMBOLS = ["e", "E", "+", "-", "."]
export const TITLE_TYPE = 2
export const POSITION_TYPE = 3
export const STATUS_TYPE = 4

export const CODE = {
  SUCCESS: 200,
  ISE: 500,
}

export const KEY = {
  ENTER: 'Enter',
  SPACE: 'Space',
}

export const SIMPLE_CATEGORY_TYPE = {
  DAN_TOC: 1,
  CHUC_DANH: 2,
  CHUC_VU: 3,
  LY_DO_KHAM: 3,
  TRANG_THAI_NHAN_VIEN: 4,
  MOI_QUAN_HE: 7,
  LOAI_TIEP_NHAN: 8,
  LOAI_GIA: 9,
  TUYEN: 10,
  MA_KV: 11,
  TUYEN_CHUYEN: 12,
  LY_DO_CHUYEN: 13,
  DOI_TUONG: 15,
  QUOC_GIA: 16,
  NGHE_NGHIEP: 18,
  GIOI_TINH: 19,
  TUYEN_KCB: 20,
}

export const TERM_CLASS_IDS = {
  KHAM_BENH: 1,
  XET_NGHIEM: 2,
  ICD10: 4,
  CDHA: 5,
  PTTT: 6,
  KQDT: 7,
  HTXT: 8
};
export const ID_DANG_KY_KHAM = {
  KHAM_BENH: 1,
  CAP_CUU:2
}

export const ORDER_TYPE_ID = {
  CONG_KHAM: "1",
  XET_NGHIEM: "2",
  CDHA: "4",
  THUOC: "5",
  VAT_TU: "6",
}

export const CODE_SELECT = {
  QG_VIET_NAM: "000",
  DT_KINH: "1",
  LTT_BENH_MOI: "TIEPNHAN1",
  LTT_TAI_KHAM: "TIEPNHAN2",
}

export const DEFAULT_PAGE_INDEX = 1
export const DEFAULT_PAGE_SIZE = 10
export const DEFAULT_TOTAL_PAGES = 0
export const DEFAULT_TOTAL_ELEMENTS = 0
export const MAX_PAGE_SIZE = 99999
export const SEARCH_OBJECT_MAX_SIZE = {
  pageIndex: DEFAULT_PAGE_INDEX,
  pageSize: MAX_PAGE_SIZE,
}

export const SELECTION_MODE = {
  SINGLE: 'single',
  MULTI: 'multi',
  MULTI_TABLE: 'multiline',
  SINGLE_NO_RADIO_BUTTON: 'singleNoRadioButton',
}

export enum TYPE {
  OBJECT = "object",
  STATUS = "status",
  STRING = "string",
  TEXT = "text",
  NUMBER = "number",
  INPUT = "input",
  DATE = "date",
  TEXTAREA = "textarea",
  SELECT = "select",
  SINGLE = "single",
  MULTI = 'multi',
  MULTILINE = "multiline",
  MODULE = "module",
  PASSWORD= "password",
  EXCEL = "EXCEL",
  WORD = "WORD",
  MONEY = "MONEY",
  NUMBER_FLOAT = "NUMBER_FLOAT",
  SINGLE_NO_RADIO_BUTTON = 'singleNoRadioButton',
}

export const RESPONSE_MESSAGE = {
  ADD: {
    SUCCESS: 'Thêm thành công',
  },
  UPDATE: {
    SUCCESS: 'Cập nhật thành công',
  },
  DELETE: {
    SUCCESS: "Xóa thành công"
  },

  ERROR: 'Xảy ra lỗi, vui lòng thử lại',
}

export const INPUT_VALUE = {
  BENH_NHAN: {
    LOAI_DOI_TUONG: "benhNhanCase.loaiDoiTuong",
    LOAI_TIEP_NHAN: "benhNhanCase.loaiTiepnNhan"
  }
}

export const CODE_SUCCESS = 200
export const ERROR_MESSAGE = "Có lỗi xảy ra, vui lòng thử lại!"

export const CHI_DINH_DV_TIEP_NHAN = 'CHI_DINH_DV_TIEP_NHAN';
export const CHI_DINH_DV_TIEP_DON = 'CHI_DINH_DV_TIEP_DON';

export const VARIABLE_STRING = {
  PROVINCE: "province",
  DISTRICT: "district",
  COMMUNE: "commune",
  WARD: "ward",
  DAN_TOC: "danToc",
  QUOC_TINH: "quocTich",
  LOAI_TIEP_NHAN: "loaiTiepNhan",
  TEN_DICH_VU: "tenDichVu",
  PHONG_KHAM: "phongKham",
  BMI: "BMI",
  CODE_GIOI_THIEU: "TUYEN4",
  QUANTITY: "quantity",
  PROMOTION_PERCENT: "promotionPercent",
  XU_TRI: "xuTri",
  KHAM_BO_PHAN: "khamBoPhan",
  SINH_HIEU: "sinhHieu",
  BENH_CHINH: "benhChinh",
  BENH_PHU: "benhPhu",
  DEPARTMENT: "department",
  ROOM: "room",
  TRANG_THAI: "status",
  LOAI_HANG_HOA: "loaiHangHoa",
  QDTT: "QDTT",
  NOI_SINH: "noiSinh",
  CHUYEN_KHOA: "chuyenKhoa",
  RA_VIEN: "raVien",
  XIN_VE: "xinVe",
  DUA_VE: "duaVe",
  TRON_VIEN: "tronVien",
  CHUYEN_TUYEN: "chuyenTuyen",
  TU_VONG: "tuVong",
  HINH_THUC_XU_TRI: "hinhThucXuTri",
  DOUBLE_CLICK: "doubleClick",
  GENDER: "gender",
  DOI_TUONG: "visitObjectType",
  PATIENT: "patient",
  VISIT: "visit",
  INSURANCE: "insurance",
  LST_ORDERS: "orders",
  CHUYEN_PHONG_KHAM: "chuyenPhongKham",
  TINH_HUYEN_XA: "administrativeUnit",
  PAGE_SIZE: "pageSize",
  PAGE_INDEX: "pageIndex",
}

export const TRANG_THAI_KHAM_BENH = [
  { status: 0, name: "Chờ khám" },
  { status: 1, name: "Đang khám" }
]

export enum STATUS_KHAM_BENH {
  CHO_KHAM = 0,
  DANG_KHAM = 1,
}

export const STATUS_ACTION = {
  VIEW: "VIEW",
  EDIT: "EDIT",
  DELETE: "DELETE",
  IMPORT: "IMPORT",
};

export enum TYPE_INPUT {
  STRING = "string",
  NUMBER = "number",
  OBJECT = "object",
}

export const TRANG_THAI_PHIEU = {
  CHUA_THUC_HIEN: 1,
  DA_LAY_MAU: 2,
  DA_THUC_HIEN_VA_TRA_KET_QUA: 3,
  DA_THUC_HIEN_VA_CHUA_TRA_KET_QUA: 4,
}

export const LIST_COLOR_TRANG_THAI_PHIEU = [
  {
    code: TRANG_THAI_PHIEU.CHUA_THUC_HIEN,
    name: "red",
  },
  {
    code: TRANG_THAI_PHIEU.DA_LAY_MAU,
    name: "purple",
  },
  {
    code: TRANG_THAI_PHIEU.DA_THUC_HIEN_VA_TRA_KET_QUA,
    name: "green",
  },
  {
    code: TRANG_THAI_PHIEU.DA_THUC_HIEN_VA_CHUA_TRA_KET_QUA,
    name: "orange",
  },
]

export const MESSAGE = {
  CONFIRM: {
    XOA_PHIEU: "Bạn có chắc chắn muốn xóa phiếu này không?",
    XOA_DON_THUOC: "Bạn có chắc chắn muốn xóa đơn thuốc này không?",
    BO_DICH_VU_KHONG_LAM: "Bạn có chắc chắn muốn bỏ dịch vụ này không?",
    HUY_GUI_PHIEU_CDHA: "Bạn có chắc chắn muốn hủy gửi phiếu chẩn đoán hình ảnh này không?",
    HUY_GUI_DON_THUOC: "Bạn có chắc chắn muốn hủy gửi đơn thuốc này không?",
    HUY_GUI_PHIEU_MAU: "Bạn có chắc chắn muốn hủy gửi phiếu máu này không?",
  },
  SUCCESS: {
    HUY_GUI_PHIEU: "Đã hủy gửi phiếu thành công!",
    HUY_DON_THUOC: "Đã hủy gửi đơn thuốc thành công!",
    GUI_PHIEU: "Gửi phiếu thành công!",
    GUI_DON_THUOC: "Đã gửi đơn thuốc thành công!",
    XOA_PHIEU: "Xóa phiếu thành công!",
  },
  WARNING: {
    THAY_THE_DICH_VU_KHAC: "Dịch vụ không được phép thay thế!",
    HUY_GUI_PHIEU: "Không thế hủy phiếu vì có dịch vụ đang thực hiện!"
  }
}

export const PATH_NAME = {
  KHAM_BENH: "/kham-benh",
  HANH_CHINH: "/hanh-chinh",
};
export const BOOLEAN_TYPE_IN_NUMBER = {
  TRUE: 1,
  FALSE: 2
};
export const CODE_GENERATE_FORM = {
  loaiDichVuQuantriHeThong:'SVC',
  khoaQuanTriheThong: 'DEPT',
};
export const overflowHiddenTable = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap"
}

export const textSystem = {
  fontWeight: "400",
  color: "#333",
  padding: "0.5rem",
}

export const GENDER: any[] = [
  {
    id: 0,
    title: "Không xác định",
    value: 0
  },
  {
    id: 1,
    title: "Nam",
    value: 1
  },
  {
    id: 2,
    title: "Nữ",
    value: 2
  }
]

export const TRANG_THAI = {
  CHO_DUYET_DE_XUAT: 3,
  CHO_NOP_DE_CUONG: 4,
  CHO_XET_DUYET: 5,
  CHO_PHE_DUYET: 6,
  DANG_TRIEN_KHAI: 7,
  TU_CHOI_PHE_DUYET: 8,
  CHO_NGHIEM_THU: 9,
  DA_NGHIEM_THU: 10,
  TU_CHOI_NGHIEM_THU: 11,
  TU_CHOI_DE_XUAT: 15,
  TU_CHOI_DE_CUONG: 16
}

export const MEMBER_TYPE = {
  CHU_NHIEM_DE_TAI: 1,
  THU_KY: 2,
  CAN_BO_THUC_HIEN: 3,
  GVCN: 4
}

export const ORG_TYPE = {
  TO_CHUC_CHU_TRI: 1,
  TO_CHUC_PHOI_HOP: 2
}

export const REPORT_SUB_TYPE = {
  BIEN_BAN_HOI_DONG_KHOA_HOC: 21,
  BIEN_BAN_HOI_DONG_DAO_DUC: 22,
  BIEN_BAN_GIAI_TRINH_CHINH_SUA: 23,
  PHIEU_NHAN_XET: 24,
  QUYET_DINH_PHE_DUYET_DE_TAI: 25,
  GIA_HAN: 26,
  GOP_Y_DE_XUAT: 27,
  YEU_CAU_CHINH_SUA_DE_CUONG: 28,
  QUYET_DINH_THANH_LAP_HD_KHOA_HOC: 29,
  QUYET_DINH_THANH_LAP_HD_NGHIEM_THU: 30,
  QUYET_DINH_NGHIEM_THU: 31,
  DUYET_DE_XUAT: 32,
  TU_CHOI_DE_XUAT: 33,
  XET_DUYET_DE_CUONG: 34,
  TU_CHOI_XET_DUYET_DE_CUONG: 35,
  PHE_DUYET_DE_TAI: 36,
  TU_CHOI_PHE_DUYET_DE_TAI: 37,
  NGHIEM_THU_DE_TAI: 38,
  TU_CHOI_NGHIEM_THU_DE_TAI: 39,
  DE_CUONG: 41
}

export const RELATED_PAPER = [
  {
    title: "Biên bản họp hội đồng khoa học",
    subType: REPORT_SUB_TYPE.BIEN_BAN_HOI_DONG_KHOA_HOC,
    name: "bienBanHopHoiDongKhoaHoc"
  },
  {
    title: "Biên bản họp hội đồng đạo đức",
    subType: REPORT_SUB_TYPE.BIEN_BAN_HOI_DONG_DAO_DUC,
    name: "bienBanHopHoiDongDaoDuc"
  },
  {
    title: "Biên bản giải trình các chỉnh sửa",
    subType: REPORT_SUB_TYPE.BIEN_BAN_GIAI_TRINH_CHINH_SUA,
    name: "bienBanGiaiTrinhCacChinhSua",
    hasSample: true,
    apiPath: "/files/template/explanation-record"
  },
  {
    title: "Đề cương",
    subType: REPORT_SUB_TYPE.DE_CUONG,
    name: "deCuong"
  },
  {
    title: "Phiếu nhận xét",
    subType: REPORT_SUB_TYPE.PHIEU_NHAN_XET,
    name: "phieuNhanXet",
    hasSample: true,
    apiPath: "/files/template/feedback"
  },
  {
    title: "Quyết định phê duyệt đề tài",
    subType: REPORT_SUB_TYPE.QUYET_DINH_PHE_DUYET_DE_TAI,
    name: "quyetDinhPheduyetDeTai"
  },
  {
    title: "Gia hạn",
    subType: REPORT_SUB_TYPE.GIA_HAN,
    name: "giaHan",
    hasSample: true,
    apiPath: "/files/template/extension"
  },
  {
    title: "Góp ý đề xuất",
    subType: REPORT_SUB_TYPE.GOP_Y_DE_XUAT,
    name: "gopYDeXuat"
  },
  {
    title: "Yêu cầu chỉnh sửa đề cương",
    subType: REPORT_SUB_TYPE.YEU_CAU_CHINH_SUA_DE_CUONG,
    name: "yeuCauChinhSuaDeCuong",
    hasSample: true,
    apiPath: "/files/template/outline"
  },
  {
    title: "Quyết định thành lập hội đồng khoa học",
    subType: REPORT_SUB_TYPE.QUYET_DINH_THANH_LAP_HD_KHOA_HOC,
    name: "quyetDinhThanhLapHoiDongKhoaHoc",
    hasSample: true,
    apiPath: "/files/template/committee-record"
  },
  {
    title: "Quyết định thành lập hội đồng nghiệm thu",
    subType: REPORT_SUB_TYPE.QUYET_DINH_THANH_LAP_HD_NGHIEM_THU,
    name: "quyetDinhThanhLapHoiDongNghiemThu",
    apiPath: "/files/template/committee-record"
  },
  {
    title: "Quyết định nghiệm thu",
    subType: REPORT_SUB_TYPE.QUYET_DINH_NGHIEM_THU,
    name: "quyetDinhNghiemThu"
  },
  {
    title: "Duyệt đề xuất",
    subType: REPORT_SUB_TYPE.DUYET_DE_XUAT,
    name: "duyetDeXuat"
  },
  {
    title: "Từ chối đề xuất",
    subType: REPORT_SUB_TYPE.TU_CHOI_DE_XUAT,
    name: "tuChoiDeXuat"
  },
  {
    title: "Xét duyệt đề cương",
    subType: REPORT_SUB_TYPE.XET_DUYET_DE_CUONG,
    name: "xetDuyetDeCuong"
  },
  {
    title: "Từ chối xét duyệt đề cương",
    subType: REPORT_SUB_TYPE.TU_CHOI_XET_DUYET_DE_CUONG,
    name: "tuChoiXetDuyetDeCuong"
  },
  {
    title: "Phê duyệt đề tài",
    subType: REPORT_SUB_TYPE.PHE_DUYET_DE_TAI,
    name: "pheDuyetDeTai"
  },
  {
    title: "Từ chối phê duyệt đề tài",
    subType: REPORT_SUB_TYPE.TU_CHOI_PHE_DUYET_DE_TAI,
    name: "tuChoiPheDuyetDeTai"
  },
  {
    title: "Nghiệm thu đề tài",
    subType: REPORT_SUB_TYPE.NGHIEM_THU_DE_TAI,
    name: "nghiemThuDeTai"
  },
  {
    title: "Từ chối nghiệm thu đề tài",
    subType: REPORT_SUB_TYPE.TU_CHOI_NGHIEM_THU_DE_TAI,
    name: "tuChoiNghiemThuDeTai"
  }
]

export const ACCEPT_FILE_TYPE = {
  DOC: "application/msword",
  DOCX: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  PDF: "application/pdf",
  ZIP: "application/x-zip-compressed",
  RAR: "application/x-rar-compressed"
}

export const NOTIFY_TYPEID = {
  GOP_Y_DE_XUAT: 1,
  YEU_CAU_CHINH_SUA_DE_CUONG: 2,
  DUYET_DE_XUAT: 3,
  TU_CHOI_DE_XUAT: 4,
  XET_DUYET_DE_CUONG: 5,
  TU_CHOI_XET_DUYET_DE_CUONG: 6,
  PHE_DUYET_DE_TAI: 7,
  TU_CHOI_PHE_DUYET_DE_TAI: 8,
  NGHIEM_THU_DE_TAI: 9,
  TU_CHOI_NGHIEM_THU_DE_TAI: 10
}

export const PERMISSIONS = {
  QUAN_LY_DE_TAI: "SR.MODULE.MANAGE_TOPIC",
  DE_TAI_CUA_TOI: "SR.MODULE.MY_TOPIC",
  MO_DOT: "SR.MODULE.OPEN_PERIOD",
  DANG_KY_DOT: "SR.MODULE.REGIS_PERIOD",
  HOI_DONG: "SR.MODULE.COMMITTEE",
}

export const ROLE = {
  GIAM_DOC: "SR.ROLE.DIRECTOR",
  NHOM_NGHIEN_CUU: "SR.ROLE.RESEARCHER",
  PHONG_NCKH: "SR.ROLE.MANAGER",
}

export const BUTTON_CONFIG = {
  sanPham: {
    title: "Sản phẩm",
    display: {
      role: [ROLE.PHONG_NCKH, ROLE.GIAM_DOC],
      status: [TRANG_THAI.CHO_DUYET_DE_XUAT, TRANG_THAI.CHO_NOP_DE_CUONG, TRANG_THAI.CHO_XET_DUYET, TRANG_THAI.CHO_PHE_DUYET,
        TRANG_THAI.DANG_TRIEN_KHAI, TRANG_THAI.TU_CHOI_PHE_DUYET, TRANG_THAI.CHO_NGHIEM_THU, TRANG_THAI.DA_NGHIEM_THU,
        TRANG_THAI.TU_CHOI_NGHIEM_THU, TRANG_THAI.TU_CHOI_DE_XUAT, TRANG_THAI.TU_CHOI_DE_CUONG
      ]
    },
    active: {
      status: [TRANG_THAI.DANG_TRIEN_KHAI, TRANG_THAI.CHO_NGHIEM_THU]
    }
  },
  giayTo: {
    title: "Giấy tờ",
    display: {
      role: [ROLE.PHONG_NCKH, ROLE.GIAM_DOC],
      status: [TRANG_THAI.CHO_DUYET_DE_XUAT, TRANG_THAI.CHO_NOP_DE_CUONG, TRANG_THAI.CHO_XET_DUYET, TRANG_THAI.CHO_PHE_DUYET,
        TRANG_THAI.DANG_TRIEN_KHAI, TRANG_THAI.TU_CHOI_PHE_DUYET, TRANG_THAI.CHO_NGHIEM_THU, TRANG_THAI.DA_NGHIEM_THU,
        TRANG_THAI.TU_CHOI_NGHIEM_THU, TRANG_THAI.TU_CHOI_DE_XUAT, TRANG_THAI.TU_CHOI_DE_CUONG
      ]
    },
    active: {
      status: [TRANG_THAI.CHO_DUYET_DE_XUAT, TRANG_THAI.CHO_NOP_DE_CUONG, TRANG_THAI.CHO_XET_DUYET, TRANG_THAI.CHO_PHE_DUYET,
        TRANG_THAI.DANG_TRIEN_KHAI, TRANG_THAI.TU_CHOI_PHE_DUYET, TRANG_THAI.CHO_NGHIEM_THU, TRANG_THAI.DA_NGHIEM_THU,
        TRANG_THAI.TU_CHOI_NGHIEM_THU, TRANG_THAI.TU_CHOI_DE_XUAT, TRANG_THAI.TU_CHOI_DE_CUONG
      ]
    }
  },
  khac: {
    title: "Khác",
    display: {
      role: [ROLE.PHONG_NCKH],
      status: [TRANG_THAI.CHO_DUYET_DE_XUAT, TRANG_THAI.CHO_XET_DUYET, TRANG_THAI.CHO_NGHIEM_THU, TRANG_THAI.TU_CHOI_DE_CUONG
      ]
    },
    active: {
      status: [TRANG_THAI.CHO_DUYET_DE_XUAT, TRANG_THAI.CHO_XET_DUYET, TRANG_THAI.CHO_NGHIEM_THU, TRANG_THAI.TU_CHOI_DE_CUONG
      ]
    }
  },
  duyetDeXuat: {
    title: "Duyệt đề xuất",
    display: {
      role: [ROLE.PHONG_NCKH],
      status: [TRANG_THAI.CHO_DUYET_DE_XUAT]
    },
    active: {
      status: [TRANG_THAI.CHO_DUYET_DE_XUAT]
    }
  },
  tuChoiDuyetDeXuat: {
    title: "Từ chối duyệt đề xuất",
    display: {
      role: [ROLE.PHONG_NCKH],
      status: [TRANG_THAI.CHO_DUYET_DE_XUAT]
    },
    active: {
      status: [TRANG_THAI.CHO_DUYET_DE_XUAT]
    }
  },
  taoBienBanKH: {
    title: "Tạo biên bản họp",
    display: {
      role: [ROLE.PHONG_NCKH],
      status: [TRANG_THAI.CHO_XET_DUYET]
    },
    active: {
      status: [TRANG_THAI.CHO_XET_DUYET]
    }
  },
  duyetDeCuong: {
    title: "Duyệt đề cương",
    display: {
      role: [ROLE.PHONG_NCKH],
      status: [TRANG_THAI.CHO_XET_DUYET]
    },
    active: {
      status: [TRANG_THAI.CHO_XET_DUYET]
    }
  },
  tuChoiDuyetDeCuong: {
    title: "Từ chối duyệt đề cương",
    display: {
      role: [ROLE.PHONG_NCKH],
      status: [TRANG_THAI.CHO_XET_DUYET]
    },
    active: {
      status: [TRANG_THAI.CHO_XET_DUYET]
    }
  },
  duyetDeTai: {
    title: "Phê duyệt",
    display: {
      role: [ROLE.GIAM_DOC],
      status: [TRANG_THAI.CHO_PHE_DUYET]
    },
    active: {
      status: [TRANG_THAI.CHO_PHE_DUYET]
    }
  },
  tuChoiDuyetDeTai: {
    title: "Từ chối",
    display: {
      role: [ROLE.GIAM_DOC],
      status: [TRANG_THAI.CHO_PHE_DUYET]
    },
    active: {
      status: [TRANG_THAI.CHO_PHE_DUYET]
    }
  },
  yeuCauChinhSua: {
    title: "Yêu cầu chỉnh sửa",
    display: {
      role: [ROLE.PHONG_NCKH],
      status: [TRANG_THAI.CHO_XET_DUYET]
    },
    active: {
      status: [TRANG_THAI.CHO_XET_DUYET]
    }
  },  
  taoBienBanNT: {
    title: "Tạo biên bản họp",
    display: {
      role: [ROLE.PHONG_NCKH],
      status: [TRANG_THAI.CHO_NGHIEM_THU]
    },
    active: {
      status: [TRANG_THAI.CHO_NGHIEM_THU]
    }
  },
  nghiemThu: {
    title: "Nghiệm thu",
    display: {
      role: [ROLE.PHONG_NCKH],
      status: [TRANG_THAI.CHO_NGHIEM_THU]
    },
    active: {
      status: [TRANG_THAI.CHO_NGHIEM_THU]
    }
  },
  tuChoiNghiemThu: {
    title: "Từ chối nghiệm thu",
    display: {
      role: [ROLE.PHONG_NCKH],
      status: [TRANG_THAI.CHO_NGHIEM_THU]
    },
    active: {
      status: [TRANG_THAI.CHO_NGHIEM_THU]
    }
  },
  yeuCauChinhSuaNghiemThu: {
    title: "Yêu cầu chỉnh sửa nghiệm thu",
    display: {
      role: [ROLE.PHONG_NCKH],
      status: [TRANG_THAI.CHO_NGHIEM_THU]
    },
    active: {
      status: [TRANG_THAI.CHO_NGHIEM_THU]
    }
  },
  gopY: {
    title: "Góp ý",
    display: {
      role: [ROLE.PHONG_NCKH],
      status: [TRANG_THAI.CHO_DUYET_DE_XUAT]
    },
    active: {
      status: [TRANG_THAI.CHO_DUYET_DE_XUAT]
    }
  },
  giaHan: {
    title: "Gia hạn",
    display: {
      role: [ROLE.NHOM_NGHIEN_CUU],
      status: [TRANG_THAI.DANG_TRIEN_KHAI]
    },
    active: {
      status: [TRANG_THAI.DANG_TRIEN_KHAI]
    }
  },
  nopBaoCao: {
    title: "Nộp báo cáo",
    display: {
      role: [ROLE.NHOM_NGHIEN_CUU],
      status: [TRANG_THAI.CHO_DUYET_DE_XUAT, TRANG_THAI.CHO_NOP_DE_CUONG, TRANG_THAI.CHO_XET_DUYET, TRANG_THAI.CHO_PHE_DUYET,
        TRANG_THAI.DANG_TRIEN_KHAI, TRANG_THAI.TU_CHOI_PHE_DUYET, TRANG_THAI.CHO_NGHIEM_THU, TRANG_THAI.DA_NGHIEM_THU,
        TRANG_THAI.TU_CHOI_NGHIEM_THU, TRANG_THAI.TU_CHOI_DE_XUAT, TRANG_THAI.TU_CHOI_DE_CUONG
      ]
    },
    active: {
      status: [TRANG_THAI.DANG_TRIEN_KHAI]
    }
  },
  giayToKhac: {
    title: "Giấy tờ khác",
    display: {
      role: [ROLE.NHOM_NGHIEN_CUU],
      status: [TRANG_THAI.CHO_DUYET_DE_XUAT, TRANG_THAI.CHO_NOP_DE_CUONG, TRANG_THAI.CHO_XET_DUYET, TRANG_THAI.CHO_PHE_DUYET,
        TRANG_THAI.DANG_TRIEN_KHAI, TRANG_THAI.TU_CHOI_PHE_DUYET, TRANG_THAI.CHO_NGHIEM_THU, TRANG_THAI.DA_NGHIEM_THU,
        TRANG_THAI.TU_CHOI_NGHIEM_THU, TRANG_THAI.TU_CHOI_DE_XUAT, TRANG_THAI.TU_CHOI_DE_CUONG
      ]
    },
    active: {
      status: [TRANG_THAI.CHO_DUYET_DE_XUAT, TRANG_THAI.CHO_NOP_DE_CUONG, TRANG_THAI.CHO_XET_DUYET, TRANG_THAI.CHO_PHE_DUYET,
        TRANG_THAI.DANG_TRIEN_KHAI, TRANG_THAI.TU_CHOI_PHE_DUYET, TRANG_THAI.CHO_NGHIEM_THU, TRANG_THAI.DA_NGHIEM_THU,
        TRANG_THAI.TU_CHOI_NGHIEM_THU, TRANG_THAI.TU_CHOI_DE_XUAT, TRANG_THAI.TU_CHOI_DE_CUONG
      ]
    }
  },
  xoa: {
    title: "Xóa",
    display: {
      role: [ROLE.NHOM_NGHIEN_CUU],
      status: [TRANG_THAI.CHO_DUYET_DE_XUAT]
    },
    active: {
      status: [TRANG_THAI.CHO_DUYET_DE_XUAT]
    }
  },
  deCuong: {
    title: "Đề cương",
    display: {
      role: [ROLE.NHOM_NGHIEN_CUU],
      status: [TRANG_THAI.CHO_NOP_DE_CUONG]
    },
    active: {
      status: [TRANG_THAI.CHO_NOP_DE_CUONG]
    }
  },
}

export const checkDisplayButton = (buttonName: string, statusId: number) => {
  let button = BUTTON_CONFIG[buttonName as keyof typeof BUTTON_CONFIG];
  let currentUser = localStorageItem.get(KEY_LOCALSTORAGE.ACCESS_TOKEN_DECODE);
  const authorities = currentUser ? currentUser.authorities : [];
  if(!authorities) return;
  let currentRole = authorities?.find((item: string) => item?.startsWith("SR.ROLE"));
  return button?.display?.role?.includes(currentRole) && button?.display?.status?.includes(statusId);
}

export const checkActiveButton = (buttonName: string, statusId: number) => {
  let button = BUTTON_CONFIG[buttonName as keyof typeof BUTTON_CONFIG];
  return button?.active?.status?.includes(statusId);
}