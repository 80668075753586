import { columnNamesType, OctTable } from "@oceantech/oceantech-ui";
import { Form, Formik } from "formik";
import { FC, useContext } from "react";
import { Col, FormCheck, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { AppContext } from "../../../appContext/AppContext";
import { TextFieldCustom } from "../../../category-personnel/components/text-field-custom/TextFieldCustom";
import { DEPARTMENTS } from "../../../dang-ky-de-tai/constants/constants";
import { addProposal, searchDepartment } from "../../../dang-ky-de-tai/services/services";
import { TTopicDetails } from "../../../quan-ly-de-tai/de-tai/models/TopicModel";
import { CODE, DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE } from "../../../utils/Constant";
import { TMainResearcher, TTopicSecretary } from "../../models/MyTopicModel";
import AutocompleteObjectV2 from '../../../component/AutocompleteObjectV2';

type IProps = {
  memberList: TMainResearcher[];
  topicInfo: TTopicDetails;
  mainResearcher: TMainResearcher;
  topicSecretary: TTopicSecretary;
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  formRef: React.MutableRefObject<any>;
};
const TabProposal: FC<IProps> = (props) => {
  const { memberList, topicInfo, topicSecretary, mainResearcher, isEdit, setIsEdit, formRef } = props;
  const { setIsLoading } = useContext(AppContext);

  let validationSchema = Yup.object({});

  const handleFormSubmit = async (values: TTopicDetails) => {
    let members = [
      { ...values.mainResearcher, typeId: 1 },
      { ...values.topicSecretary, typeId: 2 },
      ...memberList,
    ];
    let dataProposal = {
      name: values.name,
      urgency: values.urgency,
      objective: values.objective,
      content: values.content,
      estimatedTimeframe: values.estimatedTimeframe,
      expectedOutcome: values.expectedOutcome,
      feasibility: values.feasibility,
      otherInformation: values.otherInformation,
      members,
    };
    try {
      setIsLoading(true);
      let { data } = await addProposal(dataProposal, String(topicInfo?.id));
      if (CODE.SUCCESS === data?.code) {
        setIsLoading(false);
        toast.success("Chỉnh sửa đề xuất thành công");
      } else {
        setIsLoading(false);
        let errorMesage =
          data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
        toast.warning(errorMesage);
      }
    } catch (error) {
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      console.error(error);
    } finally {
    }
    setIsLoading(false);
    setIsEdit(false);
  };

  const memberColumns: columnNamesType[] = [
    {
      name: "STT",
      field: "stt",
      headerStyle: {
        width: "50px",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row, index, stt) => {
        return stt;
      },
    },
    {
      name: "Họ tên",
      field: "name",
      headerStyle: {
        minWidth: "150px",
      },
    },
    {
      name: "Chức vụ",
      field: "position",
      headerStyle: {
        minWidth: "200px",
      },
    },
    {
      name: "Tham gia chính",
      field: "isKeyMember",
      headerStyle: {
        minWidth: "50px",
        maxWidth: "50px",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row, index, stt) => {
        return (
          <FormCheck checked={row.isKeyMember} readOnly onChange={() => {}} />
        );
      },
    },
  ];

  return (
    <Formik<TTopicDetails>
      enableReinitialize
      innerRef={formRef}
      initialValues={{ ...topicInfo, mainResearcher, topicSecretary }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleFormSubmit(values);
        setSubmitting(false);
      }}
    >
      {({
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Modal.Body className="spaces p-0 mt-5 bg-white">
              <div className="form spaces p-10">
                <>
                  <Row className="py-2">
                    <Col xs={6}>
                      <div className="flex align-items-center">
                        <div className="label min-w-100px fw-bold">
                          Tên đề tài
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name="name"
                            disabled={!isEdit}
                            values={values.name}
                            errors={errors.name}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="py-2">
                    <Col
                      xs={12}
                      className="label min-w-100px fw-bold pb-2 text-gray"
                    >
                      Chủ nhiệm đề tài/Nghiên cứu viên chính:
                    </Col>
                    <Col xs={4}>
                      <div className="flex align-items-center px-2">
                        <div className="label min-w-100px fw-bold">Họ tên</div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name="mainResearcher.name"
                            disabled={!isEdit}
                            values={values.mainResearcher?.name}
                            errors={errors.mainResearcher}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="flex align-items-center">
                        <div className="label min-w-100px fw-bold">
                          Khoa phòng
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name="mainResearcher.department"
                            disabled={!isEdit}
                            values={values.mainResearcher?.department}
                            errors={errors.mainResearcher}
                            handleChange={handleChange}
                            // options={DEPARTMENTS}
                          />
                          {/* <AutocompleteObjectV2
                              options={[]}
                              isSearchDefauilt={true}
                              name={"mainResearcher.department"}
                              onChange={(e: any) =>  { setFieldValue("mainResearcher.department", e?.name || null) }}
                              isDisabled={!isEdit}
                              searchFunction={() =>
                                searchDepartment({
                                  pageIndex: DEFAULT_PAGE_INDEX,
                                  pageSize: MAX_PAGE_SIZE,
                                  level: 1
                                })
                              }
                              value={values.mainResearcher?.departmentOption}
                              errors={errors.mainResearcher}
                              searchObject={{}}
                              getOptionLabel={(e: any) => e?.name}
                            /> */}
                        </div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="flex align-items-center">
                        <div className="label min-w-100px fw-bold">Chức vụ</div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name="mainResearcher.position"
                            disabled={!isEdit}
                            values={values.mainResearcher?.position}
                            errors={errors.mainResearcher}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="flex align-items-center px-2 pt-6">
                        <div className="label min-w-100px fw-bold">
                          Điện thoại
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name="mainResearcher.phone"
                            disabled={!isEdit}
                            values={values.mainResearcher?.phone}
                            errors={errors.mainResearcher}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="flex align-items-center pt-6">
                        <div className="label min-w-100px fw-bold">Email</div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name="mainResearcher.email"
                            disabled={!isEdit}
                            values={values.mainResearcher?.email}
                            errors={errors.mainResearcher}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="py-2">
                    <Col xs={12} className="pb-2">
                      <span className="label min-w-100px fw-bold text-gray">
                        Thư ký đề tài
                      </span>
                      <span className="fst-italic fw-light text-gray">
                        {" "}
                        (tham gia với &gt;50% thời gian, nếu có):
                      </span>
                    </Col>
                    <Col xs={4}>
                      <div className="flex align-items-center px-2">
                        <div className="label min-w-100px fw-bold">Họ tên</div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name="topicSecretary.name"
                            disabled={!isEdit}
                            values={values.topicSecretary?.name}
                            errors={errors.topicSecretary}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="flex align-items-center">
                        <div className="label min-w-100px fw-bold">
                          Khoa phòng
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name="topicSecretary.department"
                            disabled={!isEdit}
                            values={values.topicSecretary?.department}
                            errors={errors.topicSecretary}
                            handleChange={handleChange}
                            // options={DEPARTMENTS}
                          />
                          {/* <AutocompleteObjectV2
                            options={[]}
                            isSearchDefauilt={true}
                            name={"topicSecretary.department"}
                            onChange={(e: any) =>  { setFieldValue("topicSecretary.department", e?.name || null) }}
                            isDisabled={!isEdit}
                            searchFunction={() =>
                              searchDepartment({
                                pageIndex: DEFAULT_PAGE_INDEX,
                                pageSize: MAX_PAGE_SIZE,
                                level: 1
                              })
                            }
                            value={values.topicSecretary?.departmentOption}
                            errors={errors.topicSecretary}
                            searchObject={{}}
                            getOptionLabel={(e: any) => e?.name}
                          /> */}
                        </div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="flex align-items-center">
                        <div className="label min-w-100px fw-bold">Chức vụ</div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name="topicSecretary.position"
                            disabled={!isEdit}
                            values={values.topicSecretary?.position}
                            errors={errors.topicSecretary}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="flex align-items-center px-2 pt-6">
                        <div className="label min-w-100px fw-bold">
                          Điện thoại
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name="topicSecretary.phone"
                            disabled={!isEdit}
                            values={values.topicSecretary?.phone}
                            errors={errors.topicSecretary}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="flex align-items-center pt-6">
                        <div className="label min-w-100px fw-bold">Email</div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name="topicSecretary.email"
                            disabled={!isEdit}
                            values={values.topicSecretary?.email}
                            errors={errors.topicSecretary}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="py-2">
                    <Col
                      xs={12}
                      className="label min-w-100px fw-bold pb-2 text-gray"
                    >
                      <div className="flex justify-content-between align-items-center">
                        <p className="m-0">Thành viên tham gia</p>
                      </div>
                    </Col>
                    <Col xs={12} className="member-table">
                      <OctTable
                        id="dx-tab-member"
                        className="spaces thanh-vien-table"
                        columns={memberColumns}
                        data={memberList}
                        justFilter={false}
                        clearToolbar
                        noToolbar
                        noPagination
                      />
                    </Col>
                  </Row>
                  <Row className="py-2">
                    <Col xs={12} className="pb-2">
                      <span className="label min-w-100px fw-bold">
                        1. Giải thích về tính cấp thiết của đề tài
                      </span>
                      <span className="fst-italic">
                        {" "}
                        (Nêu tầm quan trọng, bức xúc, cấp bách)
                      </span>
                    </Col>
                    <TextFieldCustom
                      className="min-h-90px"
                      type="text"
                      as={"textarea"}
                      title=""
                      name="urgency"
                      disabled={!isEdit}
                      values={values.urgency}
                      errors={errors.urgency}
                      handleChange={handleChange}
                    />
                  </Row>
                  <Row className="py-2">
                    <Col xs={12} className="pb-2">
                      <span className="label min-w-100px fw-bold">
                        2. Mục tiêu dự kiến
                      </span>
                    </Col>
                    <TextFieldCustom
                      className="min-h-90px"
                      type="text"
                      as={"textarea"}
                      title=""
                      name="objective"
                      disabled={!isEdit}
                      values={values.objective}
                      errors={errors.objective}
                      handleChange={handleChange}
                    />
                  </Row>
                  <Row className="py-2">
                    <Col xs={12} className="pb-2">
                      <span className="label min-w-100px fw-bold">
                        3. Nội dung
                      </span>
                      <span className="fst-italic">
                        {" "}
                        (Trình bày phương pháp tiếp cận, thiết kế nghiên cứu và
                        nội dung nghiên cứu)
                      </span>
                    </Col>
                    <TextFieldCustom
                      className="min-h-90px"
                      type="text"
                      as={"textarea"}
                      title=""
                      name="content"
                      disabled={!isEdit}
                      values={values.content}
                      errors={errors.content}
                      handleChange={handleChange}
                    />
                  </Row>
                  <Row className="py-2">
                    <Col xs={12} className="pb-2">
                      <span className="label min-w-100px fw-bold">
                        4. Ước tính thời gian nghiên cứu
                      </span>
                    </Col>
                    <TextFieldCustom
                      className="min-h-90px"
                      type="text"
                      as={"textarea"}
                      title=""
                      name="estimatedTimeframe"
                      disabled={!isEdit}
                      values={values.estimatedTimeframe}
                      errors={errors.estimatedTimeframe}
                      handleChange={handleChange}
                    />
                  </Row>
                  <Row className="py-2">
                    <Col xs={12} className="pb-2">
                      <span className="label min-w-100px fw-bold">
                        5. Dự kiến sản phẩm
                      </span>
                      <span className="fst-italic">
                        {" "}
                        (Quy trình/bài báo/đào tạo)
                      </span>
                    </Col>
                    <TextFieldCustom
                      className="min-h-90px"
                      type="text"
                      as={"textarea"}
                      title=""
                      name="expectedOutcome"
                      disabled={!isEdit}
                      values={values.expectedOutcome}
                      errors={errors.expectedOutcome}
                      handleChange={handleChange}
                    />
                  </Row>
                  <Row className="py-2">
                    <Col xs={12} className="pb-2">
                      <span className="label min-w-100px fw-bold">
                        6. Khả năng ứng dụng vào Trung tâm/khoa phòng/bệnh viện
                      </span>
                    </Col>
                    <TextFieldCustom
                      className="min-h-90px"
                      type="text"
                      as={"textarea"}
                      title=""
                      name="feasibility"
                      disabled={!isEdit}
                      values={values.feasibility}
                      errors={errors.feasibility}
                      handleChange={handleChange}
                    />
                  </Row>
                  <Row className="py-2">
                    <Col xs={12} className="pb-2">
                      <span className="label min-w-100px fw-bold">
                        7. Thông tin khác
                      </span>
                      <span className="fst-italic">
                        {" "}
                        (nếu có, đề tài dựa vào chương trình đề tài khác)
                      </span>
                    </Col>
                    <TextFieldCustom
                      className="min-h-90px"
                      type="text"
                      as={"textarea"}
                      title=""
                      name="otherInformation"
                      disabled={!isEdit}
                      values={values.otherInformation}
                      errors={errors.otherInformation}
                      handleChange={handleChange}
                    />
                  </Row>
                </>
              </div>
            </Modal.Body>
          </Form>
        );
      }}
    </Formik>
  );
};

export default TabProposal;
