import { Field, FieldArray, Form, Formik } from "formik";
import { Button, Col, FormCheck, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import * as Yup from "yup";
import { TextFieldCustom } from "../../category-personnel/components/text-field-custom/TextFieldCustom";
import { initMember, TMember } from "../models/TopicRegistrationModel";
import { memberOptions } from "../constants/constants";
import { Fragment } from "react";
import { generateSecureRandomId } from "../../utils/AppFunction";

interface Props {
  show: boolean;
  onHide: () => void;
  onAddMembers: (members: TMember[]) => void;
}

type TAddMemberForm = {
  memberList: TMember[];
  memberQuantity: number;
};

const AddMemberDialog = (props: Props) => {
  const { show, onHide, onAddMembers } = props;

  const initValues = {
    memberList: [initMember],
    memberQuantity: 1,
  };

  const validationSchema = Yup.object({});

  const handleSubmit = async (values: TAddMemberForm) => {
    let members = values.memberList.map((member) => {
      return {
        fakeId: generateSecureRandomId(),
        name: member.name,
        position: member.position,
        typeId: member.typeId,
        isKeyMember: member?.isKeyMember ? true : false
      }
    })
    onAddMembers(members);
    onHide();
  };

  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      size="lg"
      scrollable
      className="spaces h-calc-vh-50"
    >
      <Formik
        enableReinitialize
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header className="header-modal min-h-40px" closeButton>
              <Modal.Title className="py-4">
                Thêm mới thành viên tham gia
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="spaces p-0 bg-white">
              <div className="spaces p-10">
                <Row className="py-2">
                  <Col xs={12}>
                    <div className="flex align-items-center">
                      <div className="label min-w-150px fw-bold">
                        Số lượng thành viên
                      </div>
                      <div className="flex-1">
                        <TextFieldCustom
                          type="number"
                          title=""
                          name="memberQuantity"
                          values={values.memberQuantity}
                          errors={errors.memberQuantity}
                          handleChange={(e: React.ChangeEvent<any>) => {
                            let newMemberList: TMember[] = Array.from(
                              { length: Number(e?.target?.value) },
                              () => ({
                                id: "",
                                name: "",
                                position: "",
                                typeId: 3,
                                isKeyMember: false
                              })
                            );
                            setFieldValue("memberList", newMemberList);
                            handleChange(e);
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="py-2">
                  <FieldArray
                    name="memberList"
                    render={() => (
                      <Fragment key={'memberList'}>
                        {values?.memberList?.map((member, index) => (
                          <div
                            className={`member py-2 border${
                              index === 0 ? "" : "-bottom"
                            }`}
                          >
                            <Col xs={12}>
                              <div className="flex align-items-center pb-4">
                                <div className="label min-w-150px fw-bold text-pri spaces pl-16">
                                  Thành viên {index + 1}
                                </div>
                                <div className="flex-1">
                                  <Select
                                    name={`member${index + 1}`}
                                    value={values?.memberList?.[index]}
                                    onChange={(e) => {
                                      setFieldValue(`memberList.${index}`, e);
                                    }}
                                    getOptionLabel={(option) =>
                                      option?.name || ""
                                    }
                                    getOptionValue={(option) =>
                                      option?.id || ""
                                    }
                                    options={memberOptions}
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col xs={12}>
                              <div className="flex align-items-center pb-4">
                                <div className="label min-w-150px fw-bold text-pri spaces pl-16">
                                  Chức vụ
                                </div>
                                <div className="flex-1">
                                  <TextFieldCustom
                                    type="text"
                                    disabled
                                    title=""
                                    name="chucVu"
                                    values={
                                      values?.memberList?.[index]?.position
                                    }
                                    errors={""}
                                    handleChange={() => {}}
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col xs={12}>
                              <div className="flex align-items-center">
                                <div className="label min-w-150px fw-bold text-pri spaces pl-16">
                                  Tham gia chính
                                </div>
                                <div className="flex-1">
                                  <Field
                                    type="checkbox"
                                    name={`memberList.${index}.isKeyMember`}
                                    checked={member.isKeyMember}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            </Col>
                          </div>
                        ))}
                      </Fragment>
                    )}
                  />
                </Row>
              </div>
            </Modal.Body>
            <Modal.Footer className="flex justify-content-center">
              <Button
                variant="primary"
                className="min-w-75px fw-bold"
                type="submit"
              >
                Lưu
              </Button>
              <Button
                variant="secondary"
                className="min-w-75px fw-bold"
                onClick={onHide}
              >
                Hủy
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddMemberDialog;
