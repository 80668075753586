import axios from "axios";
import { TMyTopicFilter } from "../models/MyTopicModel";

const API_PATH = process.env.REACT_APP_EMR_API_URL;

export const searchTopic = (data: TMyTopicFilter) => {
  let url = `${API_PATH}/topics/mine`;
  delete data.yearOption;
  return axios.get(url, { params: data });
};

export const addTopicOutline = (data: any, id: string) => {
  let url = `${API_PATH}/topics/${id}/outline`;
  return axios.post(url, data);
};

export const updateTopicOutline = (data: any, id: string) => {
  let url = `${API_PATH}/topics/${id}/outline`;
  return axios.put(url, data);
};

export const getTopicById = (id: string) => {
  let url = `${API_PATH}/topics/${id}`;
  return axios.get(url);
};

export const addReportProduct = (data: any) => {
  let url = `${API_PATH}/topics/report`;
  return axios.post(url, data);
};

export const getAttachmentList = (data: { topicId: string, typeId: string }) => {
  let url = `${API_PATH}/files`;
  return axios.get(url, { params: data });
};

export const deleteTopicById = (id: string) => {
  let url = `${API_PATH}/topics/${id}`;
  return axios.delete(url);
};

export const getAttachmentProduct = (data: { topicId: string }) => {
  let url = `${API_PATH}/topics/report`;
  return axios.get(url, { params: data });
};

export const getTopicMinutes = (id: string, committeeRecordTypeId: number) => {
  let url = `${API_PATH}/topics/${id}/committee_record?committeeRecordTypeId=${committeeRecordTypeId}`;
  return axios.get(url);
};