import moment from "moment";
import React, { useContext, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { AppContext } from "../../../appContext/AppContext";
import { CODE, TYPE } from "../../../utils/Constant";
import { getTopicById, regisMinute } from "../services/services";
import { TDataMinutes } from "../models/TopicModel";
const TYPE_MINUTES = {
  HD: {
    id: 1,
    name: `Hội đồng xét duyệt thẩm định
    đề cương nhiệm vụ KH&CN cấp cơ sở.`,
  },
  NT: {
    id: 2,
    name: `Hội đồng nghiệm thu thẩm định đề tài.`,
  },
};
interface Props {
  show: boolean;
  onHide: () => void;
  id: string;
  onRefresh: () => Promise<void>;
}

const MinutesOfMeetingDialog = (props: Props) => {
  const { show, onHide, id, onRefresh } = props;
  const { setIsLoading } = useContext(AppContext);
  const [dataMinutes, setDataMinutes] = React.useState<TDataMinutes>({
    typeId: 0,
  });
  const [text, setText] = React.useState<any>("");
  const [line, setLine] = React.useState<any>({});
  const [topicDetails, setTopicDetails] = React.useState<any>({});
  const [name, setName] = React.useState<any>("");
  const [numRows, setNumRows] = React.useState<any>({});
  const [stateMinutes, setStateMinutes] = React.useState<any>();
  useEffect(() => {
    getDetails(id);
  }, [id]);
  useEffect(() => {
    if (topicDetails.statusId === 5) {
      console.log(topicDetails.statusName);

      setDataMinutes({ ...dataMinutes, typeId: TYPE_MINUTES.HD.id });
    }
    if (topicDetails.statusId === 9) {
      console.log(topicDetails.statusName);
      setDataMinutes({ ...dataMinutes, typeId: TYPE_MINUTES.NT.id });
    }
  }, [topicDetails]);

  useEffect(() => {
    if (topicDetails) {
      setDataMinutes((prev) => ({
        ...prev,
        topicName: topicDetails.name,
        code: topicDetails.code,
      }));
    }
  }, [topicDetails]);
  useEffect(() => {
    setLine({
      ...line,
      [name]: topicDetails?.[name]?.split("\n"),
    });
  }, [name, topicDetails]);

  const handleChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    if (type === "radio") {
      const inputValue = Number(value);
      setDataMinutes({ ...dataMinutes, [name]: inputValue });
    }
    if (type !== "radio") {
      setText(value);
      autoResize(e.target);
      setDataMinutes({ ...dataMinutes, [name]: value });
    }
    if (name === "numAttendees") {
      setDataMinutes((prevData) => {
        const newNumAttendees = Number(value);
        return {
          ...prevData,
          [name]: newNumAttendees,
          numAbsent: 5 - newNumAttendees,
        };
      });
    }

    setName(name);
  };
  const addDataMinute = async (data: any) => {
    try {
      const response = await regisMinute(id, data);
      if (response?.data?.code === CODE.SUCCESS) {
        toast.success("Đăng ký thành công !");
        onHide();
        onRefresh();
        setTopicDetails({});
      }
    } catch (e) {
      toast.error("Đăng ký thất bại !");
    }
  };
  const handleSubmit = () => {
    const day = String(dataMinutes?.dayDate || "").padStart(2, "0");
    const month = String(dataMinutes?.monthDate || "").padStart(2, "0");
    const year = String(dataMinutes.yearDate || "");
    const dayNumber = Number(day);
    const monthNumber = Number(month);
    const yearNumber = Number(year);
    const isDayValid = !isNaN(dayNumber) && dayNumber >= 1 && dayNumber <= 31;
    const isMonthValid =
      !isNaN(monthNumber) && monthNumber >= 1 && monthNumber <= 12;
    const isYearValid =
      !isNaN(yearNumber) &&
      yearNumber >= 1900 &&
      yearNumber <= new Date().getFullYear();
    if (!day || !month || !year) {
      toast.warning("Ngày, tháng và năm không được để trống!");
      return;
    }
    if (!isDayValid || !isMonthValid || !isYearValid) {
      toast.warning("Ngày, tháng hoặc năm không hợp lệ!");
      return;
    }
    const dateObject = new Date(yearNumber, monthNumber - 1, dayNumber);
    if (
      dateObject.getDate() !== dayNumber ||
      dateObject.getMonth() + 1 !== monthNumber ||
      dateObject.getFullYear() !== yearNumber
    ) {
      toast.error("Ngày không hợp lệ cho tháng và năm đã cho!");
      return;
    }

    const date = moment(dateObject, "YYYY/MM/DD").format("YYYY-MM-DD");
    const { dayDate, monthDate, yearDate, ...payload } = dataMinutes;
    const updatedDataMinutes = {
      ...payload,
      date: date,
    };

    if (
      !dataMinutes.chairman ||
      !dataMinutes.committeeConclusionId ||
      !dataMinutes.committeeRecommendation ||
      !dataMinutes.committeeFeedback ||
      !dataMinutes.numAttendees
    ) {
      toast.warning("Vui lòng nhập đủ thông tin!");
      return;
    }
    if (dataMinutes.numAbsent && !dataMinutes.absentMembers) {
      toast.warning("Vui lòng nhập thông tin thành viên vắng mặt !");
      return;
    }
    addDataMinute(updatedDataMinutes);
 
  };
  const autoResize = (textarea: any) => {
    const name = textarea.name;
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
    setNumRows({
      ...numRows,
      [name]: textarea.scrollHeight / 24,
    });
  };
  const getDetails = async (id: string) => {
    try {
      setIsLoading(true);
      let { data } = await getTopicById(id);
      if (data?.code === CODE.SUCCESS) {
        setTopicDetails(data?.data);
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };
  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      size="lg"
      className="spaces h-calc-vh-50"
      scrollable
    >
      <div className="stepper stepper-links d-flex flex-column">
        <Modal.Header className="header-modal min-h-40px" closeButton>
          <Modal.Title className="py-4">Biên bản họp hội đồng</Modal.Title>
        </Modal.Header>
        <Modal.Body className="spaces p-0 bg-white mxh-60vh">
          <div className="a4-page">
            <div className="a4-container">
              <div className="header-container">
                <div className="left-column center-text">
                  <h3 className="bold">BỘ Y TẾ</h3>
                  <h3 className="bold underline">BỆNH VIỆN ĐA KHOA SÓC SƠN </h3>
                </div>
                <div className="right-column center-text">
                  <p className="bold">CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
                  <p className="underline">Độc lập – Tự do – Hạnh phúc</p>
                  <p className="center-text">
                    Hà nội, ngày ..... tháng ..... năm 20.....
                  </p>
                </div>
              </div>

              <h3 className="text-center bold section">
                BIÊN BẢN HỌP HỘI ĐỒNG
              </h3>
              <h4 className="text-center bold">
                ĐÁNH GIÁ THUYẾT MINH ĐỀ TÀI KH&CN CẤP CƠ SỞ
              </h4>

              <div className="section">
                <p>
                  <b>1. Tên đề tài: </b>
                  <span className="relative">
                    <input
                      className="no-padding custom-input"
                      type="text"
                      title="Số hội đồng"
                      name="name"
                      value={dataMinutes?.topicName}
                      style={{ width: "100%", display: "inline-block" }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      readOnly
                    />
                    <span className="dot-line"></span>
                  </span>
                </p>

                <p>
                  <b>2. Quyết định thành lập Hội đồng:</b> Số
                  <span className="relative">
                    <input
                      className="no-padding custom-input"
                      type="text"
                      title="Số hội đồng"
                      name="code"
                      value={dataMinutes?.code}
                      style={{
                        width: "70px",
                        textAlign: "center",
                        display: "inline-block",
                      }}
                      readOnly
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <span className="dot-line"></span>
                  </span>
                  /QĐ-BV của
                  <span className="relative">
                    <input
                      className="no-padding custom-input"
                      type="text"
                      name="supervisor"
                      value={dataMinutes?.supervisor}
                      style={{
                        width: "210px",
                        display: "inline-block",
                        marginLeft: "8px",
                      }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <span className="dot-line"></span>
                  </span>
                </p>

                <p>
                  <b>3. Địa điểm và thời gian:</b>
                  <span className="relative">
                    <input
                      className="no-padding custom-input"
                      type="text"
                      name="place"
                      value={dataMinutes?.place}
                      style={{
                        width: "150px",
                        display: "inline-block",
                        marginLeft: "8px",
                      }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <span className="dot-line"></span>
                  </span>{" "}
                  ngày{" "}
                  <span className="relative">
                    <input
                      className="no-padding custom-input"
                      type="text"
                      title="Số hội đồng"
                      name="dayDate"
                      value={dataMinutes?.dayDate}
                      style={{
                        width: "40px",
                        display: "inline-block",
                        marginLeft: "8px",
                      }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <span className="dot-line"></span>
                  </span>
                  /
                  <span className="relative">
                    <input
                      className="no-padding custom-input"
                      type="text"
                      name="monthDate"
                      value={dataMinutes?.monthDate}
                      style={{
                        width: "40px",
                        display: "inline-block",
                        marginLeft: "8px",
                      }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <span className="dot-line"></span>
                  </span>
                  /{" "}
                  <span className="relative">
                    <input
                      className="no-padding custom-input"
                      type="text"
                      name="yearDate"
                      value={dataMinutes?.yearDate}
                      style={{
                        width: "40px",
                        display: "inline-block",
                        marginLeft: "8px",
                      }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <span className="dot-line"></span>
                  </span>
                </p>
                <p>
                  <b>
                    4. Số thành viên hội đồng có mặt trên tổng số thành viên:
                  </b>
                  <span className="relative">
                    <input
                      className="no-padding custom-input"
                      type="number"
                      name="numAttendees"
                      value={dataMinutes?.numAttendees || ""}
                      style={{
                        width: "30px",
                        display: "inline-block",
                        marginLeft: "8px",
                      }}
                      min={0}
                      max={5}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        if (value >= 0 && value <= 5) {
                          handleChange(e);
                        } else {
                          e.target.value =
                            dataMinutes?.numAttendees !== undefined
                              ? String(dataMinutes.numAttendees)
                              : "";
                        }
                      }}
                    />
                    <span className="dot-line"></span>
                  </span>
                  / 5 người.
                </p>
                <p>
                  Vắng mặt
                  <span className="relative">
                    <input
                      className="no-padding custom-input"
                      type="text"
                      name="numAbsent"
                      value={dataMinutes?.numAbsent}
                      style={{
                        width: "20px",
                        display: "inline-block",
                        marginLeft: "8px",
                      }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      readOnly
                    />
                    <span className="dot-line"></span>
                  </span>
                  người gồm các thành viên:
                  <div className="relative">
                    <textarea
                      className="no-padding custom-input"
                      wrap="hard"
                      name="absentMembers"
                      value={dataMinutes?.absentMembers}
                      onChange={(e) => handleChange(e)}
                      style={{
                        fontFamily: "Times New Roman",
                        fontSize: "16px",
                        display: "block",
                        zIndex: "1",
                        width: "100%",
                        outline: "unset",
                        position: "relative",
                        resize: "none",
                        whiteSpace: "pre-wrap",
                        overflowWrap: "break-word",
                        height: "auto",
                        minHeight: "24px",
                        backgroundColor: "transparent",
                      }}
                    />
                    {Array.from({
                      length: isNaN(numRows?.absentMembers)
                        ? 1
                        : numRows?.absentMembers,
                    })?.map((item: any, index: any) => (
                      <span
                        key={index}
                        style={{
                          position: "absolute",
                          top: `${
                            (1 / numRows?.absentMembers || 0) * 100 * index
                          }%`,
                          right: "0",
                          left: "0",
                          width: "100%",
                          height: `24px`,
                          borderBottom: "1px dashed",
                          transform: "translateY(-2px)",
                          backgroundColor: "transparent",
                        }}
                      ></span>
                    ))}
                  </div>
                </p>
              </div>

              <div className="section">
                <h4 className="bold">B. Nội dung làm việc của hội đồng:</h4>
                <p>
                  <b>
                    1. Công bố Quyết định thành lập{" "}
                    {dataMinutes.typeId === 1
                      ? TYPE_MINUTES.HD.name
                      : TYPE_MINUTES.NT.name}
                  </b>
                </p>
                <p>
                  Hội đồng có {dataMinutes?.numAttendees}/5 thành viên tham dự
                  đủ điều kiện để họp Hội đồng.
                </p>

                <p>
                  <b>2. Chủ tịch Hội đồng:</b>
                  <span className="relative">
                    <input
                      className="no-padding custom-input"
                      type="text"
                      title="Số hội đồng"
                      name="chairman"
                      value={dataMinutes?.chairman}
                      style={{
                        width: "210px",
                        display: "inline-block",
                        marginLeft: "8px",
                      }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <span className="dot-line"></span>
                  </span>
                  chủ trì buổi họp.
                </p>
                <p>
                  <b>2.1.</b>{" "}
                  <span className="relative">
                    <input
                      className="no-padding custom-input"
                      type="text"
                      title="Số hội đồng"
                      name="presenter"
                      value={dataMinutes?.presenter}
                      style={{
                        width: "110px",
                        display: "inline-block",
                        marginLeft: "8px",
                      }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <span className="dot-line"></span>
                  </span>{" "}
                  –{" "}
                  <span className="relative">
                    <input
                      className="no-padding custom-input"
                      type="text"
                      name="presenterDuty"
                      value={dataMinutes?.presenterDuty}
                      style={{
                        width: "110px",
                        display: "inline-block",
                        marginLeft: "8px",
                      }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <span className="dot-line"></span>
                  </span>{" "}
                  đề tài thay mặt nhóm nghiên cứu trình bày tóm tắt đề cương
                  nghiên cứu.
                </p>
                <p>
                  <b>2.2.</b> Các thành viên Hội đồng lần lượt nhận xét góp ý
                  cho đề cương:
                </p>

                <div className="relative">
                  <textarea
                    className="no-padding custom-input"
                    title="Nhập nhận xét"
                    wrap="hard"
                    name="committeeFeedback"
                    value={dataMinutes?.committeeFeedback}
                    onChange={(e) => handleChange(e)}
                    style={{
                      fontFamily: "Times New Roman",
                      fontSize: "16px",
                      display: "block",
                      zIndex: "1",
                      width: "100%",
                      outline: "unset",
                      position: "relative",
                      resize: "none",
                      whiteSpace: "pre-wrap",
                      overflowWrap: "break-word",
                      height: "auto",
                      minHeight: "24px",
                      backgroundColor: "transparent",
                    }}
                  />
                  {Array.from({
                    length: isNaN(numRows?.committeeFeedback)
                      ? 1
                      : numRows?.committeeFeedback,
                  })?.map((item: any, index: any) => (
                    <span
                      key={index}
                      style={{
                        position: "absolute",
                        top: `${
                          (1 / numRows?.committeeFeedback || 0) * 100 * index
                        }%`,
                        right: "0",
                        left: "0",
                        width: "100%",
                        height: `24px`,
                        borderBottom: "1px dashed",
                        transform: "translateY(-2px)",
                        backgroundColor: "transparent",
                      }}
                    ></span>
                  ))}
                </div>
              </div>
              <div className="section">
                <h4 className="bold">C.Kết luận, kiến nghị của hội đồng </h4>
                <p>
                  <b>1. Kiến nghị những nội dung cần điều chỉnh, sửa đổi:</b>
                </p>
                <div className="relative">
                  <textarea
                    className="no-padding custom-input"
                    title="Nhập nhận xét"
                    wrap="hard"
                    name="committeeRecommendation"
                    value={dataMinutes?.committeeRecommendation}
                    onChange={(e) => handleChange(e)}
                    style={{
                      fontFamily: "Times New Roman",
                      fontSize: "16px",
                      display: "block",
                      zIndex: "1",
                      width: "100%",
                      outline: "unset",
                      position: "relative",
                      resize: "none",
                      whiteSpace: "pre-wrap",
                      overflowWrap: "break-word",
                      height: "auto",
                      minHeight: "24px",
                      backgroundColor: "transparent",
                    }}
                  />
                  {Array.from({
                    length: isNaN(numRows?.committeeRecommendation)
                      ? 1
                      : numRows?.committeeRecommendation,
                  })?.map((item: any, index: any) => (
                    <span
                      key={index}
                      style={{
                        position: "absolute",
                        top: `${
                          (1 / numRows?.committeeRecommendation || 0) *
                          100 *
                          index
                        }%`,
                        right: "0",
                        left: "0",
                        width: "100%",
                        height: `24px`,
                        borderBottom: "1px dashed",
                        transform: "translateY(-2px)",
                        backgroundColor: "transparent",
                      }}
                    ></span>
                  ))}
                </div>
              </div>
              <div className="section">
                <h4 className="bold">2. Kết luận:</h4>
                <div className="checkbox-container">
                  <label>
                    <input
                      type="radio"
                      name="committeeConclusionId"
                      value={1}
                      onChange={(e) => handleChange(e)}
                    />
                    Đề nghị thực hiện.{" "}
                  </label>
                  <br />
                  <label>
                    <input
                      type="radio"
                      name="committeeConclusionId"
                      value={2}
                      onChange={(e) => handleChange(e)}
                    />
                    Đề nghị thực hiện với các điều chỉnh nêu dưới đây.{" "}
                  </label>
                  <br />
                  <label>
                    <input
                      type="radio"
                      name="committeeConclusionId"
                      value={3}
                      onChange={(e) => handleChange(e)}
                    />
                    Đề nghị không thực hiện.
                  </label>
                </div>
              </div>

              <div className="signature-container">
                <div className="left-signature">
                  <p>
                    <b>THƯ KÝ</b>
                  </p>
                  <p>(Họ tên và chữ ký)</p>
                </div>

                <div className="right-signature">
                  <p>
                    <b>CHỦ TỊCH HỘI ĐỒNG</b>
                  </p>
                  <p>(Họ tên và chữ ký)</p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="flex justify-content-center">
          <Button
            variant="primary"
            className="min-w-75px fw-bold"
            type="submit"
            onClick={handleSubmit}
          >
            {"Gửi"}
          </Button>
          <Button
            variant="secondary"
            className="min-w-75px fw-bold"
            onClick={onHide}
          >
            Hủy
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
export default MinutesOfMeetingDialog;
