import { FC, useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { AppContext } from "../../../appContext/AppContext";
import { CODE } from "../../../utils/Constant";
import { MINMUTES_TYPE, TABS } from "../../constants/constants";
import { getTopicMinutes } from "../../services/services";
import moment from "moment";

type IProps = {
  currentTab: string;
  topicId?: string;
};
const TabMinutes: FC<IProps> = (props) => {

  const { currentTab, topicId } = props;
  const { setIsLoading } = useContext(AppContext);

  const [minutesInfo, setMinutesInfo] = useState<any>();

  useEffect(() => {
    if(!currentTab || !topicId) return;
    getMinutesInfo(topicId);
  }, [currentTab, topicId])

  const getMinutesInfo = async (topicId: string) => {
    let minutesType = null;
    switch (currentTab) {
      case TABS.SCIENCE_MINUTES.eventKey:
        minutesType = MINMUTES_TYPE.SCIENCE;
        break;
      case TABS.ACCEPTANCE_MINUTES.eventKey:
        minutesType = MINMUTES_TYPE.ACCEPTANCE;
        break;
      default:
        break;
    }
    if(!minutesType) return;
    try {
      setIsLoading(true);
      let { data } = await getTopicMinutes(topicId, minutesType);
      if (data?.code === CODE.SUCCESS) {
        setMinutesInfo(data?.data);
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <>
      <Modal.Body className="spaces p-0 bg-white">
        {/* <div className="form spaces p-10">
          <>
            <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 pb-2">
              A. Thông tin chung
            </h4>
            <div>1. Tên đề tài: {minutesInfo?.topicName}</div>
            <div>2. Quyết định thành lập Hội đồng: Số {minutesInfo?.code} ngày {minutesInfo?.date ? moment(minutesInfo?.date).format("DD/MM/YYYY") : ""} của {minutesInfo?.supervisor}</div>
            <div>3. Địa điểm và thời gian: {minutesInfo?.place}, ngày {minutesInfo?.date ? moment(minutesInfo?.date).format("DD/MM/YYYY") : ""}</div>
            <div>4. Số thành viên hội đồng có mặt trên tổng số thành viên: {minutesInfo?.numAttendees}/5 người. Vắng mặt {minutesInfo?.numAbsent} người, gồm các thành viên: {minutesInfo?.absentMembers}</div>
            <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 pb-2">
              B. Nội dung làm việc của hội đồng
            </h4>
            <div>1. Công bố Quyết định thành lập Hội đồng xét duyệt, thẩm định đề cương nhiệm vụ KH&CN cấp cơ sở.</div>
            <div>Hội đồng có {minutesInfo?.numAttendees}/5 thành viên tham dự, đủ điều kiện để họp Hội đồng.</div>
            <div>2. Chủ tịch Hội đồng {minutesInfo?.chairman} chủ trì buổi họp.</div>
            <div>2.1. {minutesInfo?.presenter} - {minutesInfo?.presenterDuty} đề tài thay mặt nhóm nghiên cứu trình bày tóm tắt đề cương nghiên cứu.</div>
            <div>2.2. Các thành Hội đồng lần lượt nhận xét, góp ý cho đề cương:</div>
            <div>(chi tiết ý kiến của các thành viên Hội đồng trong tài liệu kèm theo)</div>
            <div>{minutesInfo?.committeeFeedback}</div>
            <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 pb-2">
              C. Kết luận, kiến nghị của hội đồng
            </h4>
            <div>1. Kiến nghị những nội dung cần điều chỉnh, sửa đổi:</div>
            <div>{minutesInfo?.committeeRecommendation}</div>
            <div>2. Kết luận:</div>
          </>
        </div> */}
        <div className="a4-page">
            <div className="a4-container">
              <div className="header-container">
                <div className="left-column center-text">
                  <h3 className="bold">BỘ Y TẾ</h3>
                  <h3 className="bold underline">BỆNH VIỆN ĐA KHOA SÓC SƠN</h3>
                </div>
                <div className="right-column center-text">
                  <p className="bold m-0">CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
                  <p className="underline bold spaces mb-8">Độc lập - Tự do - Hạnh phúc</p>
                  <p className="center-text spaces mt-2">
                    Hà nội, ngày ..... tháng ..... năm 20.....
                  </p>
                </div>
              </div>

              <h3 className="text-center bold section fs-3">
                BIÊN BẢN HỌP HỘI ĐỒNG
              </h3>
              <h4 className="text-center bold fs-3">
                ĐÁNH GIÁ THUYẾT MINH ĐỀ TÀI KH&CN CẤP CƠ SỞ
              </h4>

              <div className="section">
                <p>
                  <b>1. Tên đề tài: </b>{minutesInfo?.topicName}
                </p>

                <p>
                  <b>2. Quyết định thành lập Hội đồng:</b> Số {minutesInfo?.code}/QĐ-BV ngày ...../...../20... của {minutesInfo?.supervisor}
                </p>

                <p>
                  <b>3. Địa điểm và thời gian:</b>&nbsp;
                  {minutesInfo?.place} ngày {minutesInfo?.date ? moment(minutesInfo?.date).format('DD/MM/YYYY') : ''}
                </p>
                <p>
                  <b>
                    4. Số thành viên hội đồng có mặt trên tổng số thành viên: &nbsp;
                  </b>
                  {minutesInfo?.numAttendees}/5 người.
                </p>
                <p className="m-0">
                  Vắng mặt {minutesInfo?.numAbsent} người gồm các thành viên: {minutesInfo?.absentMembers}
                </p>
              </div>

              <div className="section">
                <h4 className="bold">B. Nội dung làm việc của hội đồng:</h4>
                <p>
                  <b>1</b>. Công bố Quyết định thành lập Hội đồng xét duyệt, thẩm định đề cương nhiệm vụ KH&CN cấp cơ sở.
                </p>
                <p>
                  Hội đồng có {minutesInfo?.numAttendees}/5 thành viên tham dự
                  đủ điều kiện để họp Hội đồng.
                </p>

                <p>
                  <b>2</b>. Chủ tịch Hội đồng: &nbsp;{minutesInfo?.chairman} chủ trì buổi họp.
                </p>
                <p>
                  <b>2.1.</b> {minutesInfo?.presenter} - {minutesInfo?.presenterDuty} đề tài thay mặt nhóm nghiên cứu trình bày tóm tắt đề cương nghiên cứu.
                </p>
                <p className="m-0">
                  <b>2.2.</b> Các thành viên Hội đồng lần lượt nhận xét góp ý cho đề cương:
                </p>
                <p className="m-0">{minutesInfo?.committeeFeedback}</p>
              </div>
              <div className="section">
                <h4 className="bold">C.Kết luận, kiến nghị của hội đồng </h4>
                <p className="m-0">
                  <b>1. Kiến nghị những nội dung cần điều chỉnh, sửa đổi:</b>
                </p>
                <p className="m-0">{minutesInfo?.committeeRecommendation}</p>
              </div>
              <div className="section">
                <h4 className="bold">2. Kết luận:</h4>
                <div className="checkbox-container">
                  <label>
                    <input
                      type="radio"
                      name="committeeConclusionId"
                      value={1}
                      checked={minutesInfo?.committeeConclusionId === 1}
                    />
                    &nbsp;Đề nghị thực hiện.{" "}
                  </label>
                  <br />
                  <label>
                    <input
                      type="radio"
                      name="committeeConclusionId"
                      value={2}
                      checked={minutesInfo?.committeeConclusionId === 2}
                    />
                    &nbsp;Đề nghị thực hiện với các điều chỉnh nêu dưới đây.{" "}
                  </label>
                  <br />
                  <label>
                    <input
                      type="radio"
                      name="committeeConclusionId"
                      value={3}
                      checked={minutesInfo?.committeeConclusionId === 3}
                    />
                    &nbsp;Đề nghị không thực hiện.
                  </label>
                </div>
              </div>

              <div className="signature-container">
                <div className="left-signature">
                  <p>
                    <b>THƯ KÝ</b>
                  </p>
                  <p>(Họ tên và chữ ký)</p>
                </div>

                <div className="right-signature">
                  <p>
                    <b>CHỦ TỊCH HỘI ĐỒNG</b>
                  </p>
                  <p>(Họ tên và chữ ký)</p>
                </div>
              </div>
            </div>
          </div>
      </Modal.Body>
    </>
  );
};
export default TabMinutes;
