import axios from "axios";
import { TBatchOfRegistrationFilter } from "../models/TopicRegistrationModel";

const API_PATH = process.env.REACT_APP_EMR_API_URL;
const API_XADMIN = process.env.REACT_APP_XADMIN_API_URL;

export const searchBatchOfRegistration = (data: TBatchOfRegistrationFilter) => {
  let url = `${API_PATH}/regis-periods/search`;
  delete data.yearOption;
  delete data.statusOption;
  return axios.get(url, { params: data });
};
export const registerTopic = (data: any) => {
  let url = `${API_PATH}/topics/register`;
  return axios.post(url, data);
};

export const registerTopicAgain = (data: any, id: string) => {
  let url = `${API_PATH}/topics/${id}/re-register`;
  return axios.post(url, data);
};

export const addProposal = (data: any, id: string) => {
  let url = `${API_PATH}/topics/${id}/propose`;
  return axios.post(url, data);
};

export const getTopicById = (id: string) => {
  let url = `${API_PATH}/topics/${id}`;
  return axios.get(url);
}

export const searchDepartment = (data: any) => {
  let url = `${API_XADMIN}/departments/search`;
  return axios.get(url, { params: data });
};