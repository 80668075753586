import axios from "axios";
import { TTopicFilter } from "../models/TopicModel";

const API_PATH = process.env.REACT_APP_EMR_API_URL;

export const searchTopic = (data: TTopicFilter) => {
  let url = `${API_PATH}/topics/search`;
  delete data.yearOption;
  return axios.get(url, { params: data });
};

export const getTopicById = (id: string) => {
  let url = `${API_PATH}/topics/${id}`;
  return axios.get(url);
}

export const approveProposal = (id: string, data: any) => {
  let url = `${API_PATH}/topics/${id}/endorse-proposal`;
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(url, data, config);
};

export const approveOutline = (id: string, data: any) => {
  let url = `${API_PATH}/topics/${id}/consent-outline`;
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(url, data, config);
};

export const approveTopic = (id: string, data: any) => {
  let url = `${API_PATH}/topics/${id}/approve`;
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(url, data, config);
};

export const approveAcceptance = (id: string, data: any) => {
  let url = `${API_PATH}/topics/${id}/accept`;
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(url, data, config);
};

export const rejectOutline = (id: string, data: any) => {
  let url = `${API_PATH}/topics/${id}/repudiate-outline`;
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(url, data, config);
};

export const rejectTopic = (id: string, data: any) => {
  let url = `${API_PATH}/topics/${id}/disapprove`;
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(url, data, config);
};

export const rejectProposal = (id: string, data: any) => {
  let url = `${API_PATH}/topics/${id}/deny-proposal`;
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(url, data, config);
};

export const rejectAcceptance = (id: string, data: any) => {
  let url = `${API_PATH}/topics/${id}/reject`;
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(url, data, config);
};

export const addReportAttachment = (data: any) => {
  let url = `${API_PATH}/topics/paper`;
  return axios.post(url, data);
};

export const addExtend = (data: any) => {
  let url = `${API_PATH}/topics/extend-deadline`;
  return axios.post(url, data);
};

export const addFeedback = (data: any) => {
  let url = `${API_PATH}/topics/suggest`;
  return axios.post(url, data);
};

export const addRequestEdit = (data: any) => {
  let url = `${API_PATH}/topics/request-modification`;
  return axios.post(url, data);
};

export const getAttachmentProduct = (data: { topicId: string }) => {
  let url = `${API_PATH}/topics/report`;
  return axios.get(url, { params: data });
};

export const addRequestEditAcceptance = (data: any) => {
  let url = `${API_PATH}/topics/request-report-modification`;
  return axios.post(url, data);
};
export const  regisMinute = (id:string ,data: any) => {
  let url = `${API_PATH}/topics/${id}/committee_record`;
  return axios.post(url, data);
};