// Đợt đăng ký
export type TBatchOfRegistration = {
  id?: string;
  startDate?: string;
  name?: string;
  statusId?: number;
  statusName?: string;
  topicIdOfCurrentUser?: string;
};

export const initBatchOfRegistration: TBatchOfRegistration = {
  startDate: "",
  name: "",
};

// Tìm kiếm
export type TBatchOfRegistrationFilter = {
  year?: string;
  yearOption?: any;
  status?: string;
  keyword?: string;
  statusOption?: any;
  pageIndex?: number;
  pageSize?: number;
};

export const initFilter: TBatchOfRegistrationFilter = {
  year: "2024",
  status: "all",
  keyword: "",
  pageIndex: 1,
  pageSize: 10
};
