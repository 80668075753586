import { columnNamesType, OctTable, OctTablePagination } from "@oceantech/oceantech-ui";
import { FC, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AppContext } from "../../appContext/AppContext";
import { CODE, DEFAULT_PAGE_INDEX } from "../../utils/Constant";
import { handlePagesChange, handleRowsPerPageChange, rowsForPage } from "../../utils/PageUtils";
import AddCouncilDialog from "./components/AddAcceptanceCouncilDialog";
import Filter from "./components/Filter";
import { deleteAcceptanceCouncilById, getSummaryInfo, searchAcceptanceCouncil } from "./services/services";
import { AcceptanceCouncilContext } from "./AcceptanceCouncilManagerContext";
import { TAcceptanceCouncil, TSummaryInfo } from "./models/AcceptanceCouncilManagerModels";
import { CONFIRM_TYPE } from "./constants/constants";
import { ConfirmDialog } from "../../component/ConfirmDialog";
import { useNavigate } from "react-router-dom";
import DetailsAcceptanceCouncilDialog from "./components/DetailsAcceptanceCouncilDialog";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const AcceptanceCouncilManager: FC = () => {

  const { setIsLoading } = useContext(AppContext);
  let navigate = useNavigate();
  const { filter, setFilter, acceptanceCouncilList, setAcceptanceCouncilList } = useContext(AcceptanceCouncilContext);

  const [openDialogAdd, setOpenDialogAdd] = useState<boolean>(false);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(DEFAULT_PAGE_INDEX);
  const [summaryInfo, setSummaryInfo] = useState<TSummaryInfo>({});
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [typeConfirm, setTypeConfirm] = useState<string>("");
  const [selectedRow, setSelectedRow] = useState<TAcceptanceCouncil>({});
  const [openDetailsDialog, setOpenDetailsDialog] = useState<boolean>(false);

  useEffect(() => {
    getAcceptanceCouncilList();
    getConuncilSummaryInfo();
  }, [filter])

  useEffect(() => {
    setFilter({ ...filter, pageIndex: page, pageSize: rowsPerPage });
  }, [page, rowsPerPage])

  const getAcceptanceCouncilList = async () => {
    try {
      setIsLoading(true);
      let { data } = await searchAcceptanceCouncil(filter);
      if (data?.code === CODE.SUCCESS) {
        setOpenDialogAdd(false);
        setAcceptanceCouncilList(data?.data?.content);
        setTotalElements(data?.data?.pageable?.totalElements);
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  const getConuncilSummaryInfo = async () => {
    try {
      let { data } = await getSummaryInfo({ typeId: 2 });
      if (data?.code === CODE.SUCCESS) {
        setSummaryInfo(data?.data);
      } else {
        toast.error("Đã có lỗi xảy ra!");
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleYesClick = async (row: TAcceptanceCouncil) => {
    try {
      if(!row?.id) return;
      let api = null;
      switch (typeConfirm) {
        case CONFIRM_TYPE.DELETE_COUNCIL:
          api = deleteAcceptanceCouncilById(row.id);
          break;
        default:
          break;
      }
      if(!api) return;
      let { data } = await api;
      if(data?.code === CODE.SUCCESS) {
        setOpenConfirmDialog(false);
        toast.success(`${typeConfirm.charAt(0).toUpperCase() + typeConfirm.slice(1)} thành công!`);
        getAcceptanceCouncilList();
      } else {
        toast.warning(`${typeConfirm.charAt(0).toUpperCase() + typeConfirm.slice(1)} thất bại`);
        setOpenConfirmDialog(false);
      }
    } catch (error) {
      console.error(error);
      setOpenConfirmDialog(false);
    }
  }

  const columns: columnNamesType[] = [
    {
      name: "STT",
      field: "stt",
      headerStyle: {
        width: "50px",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row, index, stt) => {
        return <div>{(((Number(filter?.pageIndex) - 1) * Number(filter?.pageSize) + index) + 1)}</div>
      },
    },
    {
      name: "Tên hội đồng",
      field: "name",
      headerStyle: {
        minWidth: "350px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: "Số lương thành viên",
      field: "numMembers",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      name: "Thời gian diễn ra",
      field: "dateTime",
      headerStyle: {
        minWidth: "100px",
      },
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      name: "Số đề tài",
      field: "numTopics",
      headerStyle: {
        minWidth: "100px",
      },
      cellStyle: {
        textAlign: "center",
      }
    },
    {
      name: "Trạng thái",
      field: "statusName",
      headerStyle: {
        minWidth: "50px",
      },
      cellStyle: {
        textAlign: "center",
      }
    },
    {
      name: "Thao tác",
      field: "action",
      headerStyle: {
        width: "150px",
        maxWidth: "150px",
      },
      render: (row, index, stt) => {
        return (
          <div className="flex justify-content-center align-items-center gap-4 px-4">
            <span
              className="tooltips"
              data-tooltip="Chi tiết"
              data-tooltip-pos="left"
              data-tooltip-length="medium"
            >
              <i className="bi bi-eye-fill fs-2 text-pri" onClick={() => {
              setSelectedRow(row);
              setOpenDetailsDialog(true);
            }}></i>
            </span>
            <span
              className="tooltips"
              data-tooltip="Chỉnh sửa"
              data-tooltip-pos="left"
              data-tooltip-length="medium"
            >
              <i className="bi bi-pencil-fill fs-2 text-pri" onClick={() => {
              setSelectedRow(row);
              setOpenDialogAdd(true);
            }}></i>
            </span>
            <span
              className="tooltips"
              data-tooltip="Xóa"
              data-tooltip-pos="left"
              data-tooltip-length="small"
            >
              <i className={`${row?.acted === true ? "text-gray" : "text-pri"} bi bi-trash3 fs-2`} onClick={() => {
              if(row?.acted === false){
                setSelectedRow(row);
                setOpenConfirmDialog(true);
                setTypeConfirm(CONFIRM_TYPE.DELETE_COUNCIL);
              }
            }}></i>
            </span>
          </div>
        )
      }
    }
  ];

  return (
    <div className="bg-white">
      <div className="spaces bg-gray radius-2">
        <div className="bg-white spaces width-100 flex justify-content-between p-10 border-bottom">
          <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0">
            Danh sách hội đồng nghiệm thu
          </h4>
          <button
            type="button"
            onClick={() => {
              setOpenDialogAdd(true);
            }}
            className="spaces min-w-90px btn btn-primary btn btn-primary"
          >
            <i className="bi bi-plus-lg text-white fs-3"></i>
            Thêm
          </button>
        </div>
        <Filter/>
        <div className="summary-info spaces p-10 bg-white">
          <div className="label min-w-150px py-2 text-green fw-bold">
            Số đề tài cần được xét duyệt:{" "}
            <span className="fw-bold fs-4">
              {summaryInfo.numPendingInspection}
            </span>
          </div>
          <div className="label min-w-150px py-2 text-green fw-bold">
            Số đề tài đã có hội đồng xét duyệt:{" "}
            <span className="fw-bold fs-4">
              {summaryInfo.numUnderInspection}
            </span>
          </div>
          <div className="label min-w-150px py-2 text-green fw-bold">
            Số lượng hội đồng:{" "}
            <span className="fw-bold fs-4">{totalElements}</span>
          </div>
        </div>
        <div className="d-flex flex-column spaces width-100">
          <div className="bg-white">
            <div className=" spaces bg-white m-0">
              <div className="bg-white spaces mb-6 pb-15 fl-1 ml-10 mr-10">
                <OctTable
                  id="ds-hoi-dong-nghiem-thu"
                  className="spaces hoi-dong-table"
                  columns={columns}
                  data={acceptanceCouncilList}
                  justFilter={false}
                  clearToolbar
                  noToolbar
                  noPagination
                />
                <OctTablePagination
                  handlePagesChange={handlePagesChange}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  totalPages={Math.ceil(totalElements/rowsPerPage)}
                  totalElements={totalElements}
                  rowsForPage={rowsForPage}
                  numberOfElements={0}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {openDialogAdd && (
        <AddCouncilDialog
          show={openDialogAdd}
          onHide={() => {
            setOpenDialogAdd(false);
            setSelectedRow({});
          }}
          onRefresh={() => {
            getAcceptanceCouncilList();
            getConuncilSummaryInfo();
          }}
          id={selectedRow?.id || ""}
        />
      )}
      {openDetailsDialog && (
        <DetailsAcceptanceCouncilDialog
          show={openDetailsDialog}
          onHide={() => { setOpenDetailsDialog(false); setSelectedRow({})}}
          id={selectedRow?.id || ""}
        />
      )}
      {openConfirmDialog && (
        <ConfirmDialog
          show={openConfirmDialog}
          yes="Xác nhận"
          onYesClick={() => handleYesClick(selectedRow)}
          message={`Bạn có chắc muốn ${typeConfirm} này?`}
          close="Huỷ"
          onCloseClick={() => setOpenConfirmDialog(false)}
          title="Xác nhận thao tác"

        />
      )}
    </div>
  );
};

export default AcceptanceCouncilManager;
