import { FieldArray, FormikErrors, FormikHandlers } from "formik";
import { FC, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Select, { SingleValue } from 'react-select';
import { toast } from "react-toastify";
import { TextFieldCustom } from "../../../../category-personnel/components/text-field-custom/TextFieldCustom";
import { GENDER } from "../../../../utils/Constant";
import { listScienceCouncilMember } from "../../constants/constants";
import { initScienceCouncilMember, TAddScienceCouncil, TScienceCouncilDetails, TScienceCouncilMember } from "../../models/ScienceCouncilManagerModels";
import AutocompleteObjectV2 from '../../../../component/AutocompleteObjectV2';

type Iprops = {
  values: TAddScienceCouncil;
  errors: FormikErrors<TAddScienceCouncil>;
  handleChange: FormikHandlers['handleChange'];
  handleBlur:FormikHandlers['handleBlur'];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  isView?: boolean;
  details?: TScienceCouncilDetails;
};

const Step2: FC<Iprops> = (props) => {
  const { values, errors, handleChange,handleBlur, setFieldValue, isView, details } = props;

  return (
    <div className="w-100">
      <Row className="py-2">
        <Col xs={12}>
          <div className="flex align-items-center">
            <div className="label min-w-150px fw-bold">Số lượng thành viên</div>
            <div className="flex-1">
              <TextFieldCustom
                type="number"
                title=""
                name="numMembers"
                disabled
                values={values.numMembers}
                errors={errors.numMembers}
                onBlur={(e: React.FocusEvent<any>) => {
                  let quantity = Number(e.target.value);
                            const currentLength = values.members.length;
                            if (quantity > currentLength) {
                              let newMemberList: TScienceCouncilMember[] = Array.from(
                                { length: quantity -currentLength },
                                () => (initScienceCouncilMember)
                              );
                              setFieldValue("members", [...values.members, ...newMemberList]);
                            }
                            else if(quantity< currentLength){
                              setFieldValue("members", values.members.slice(0, quantity))
                            }
                 
                  handleBlur(e);
                }}
                handleChange={(e: React.ChangeEvent<any>) => {
                  let quantity = Number(e?.target?.value);
                  if (quantity > 5) {
                    toast.warning("Không được quá 5 thành viên");
                    return;
                  }
                  handleChange(e);
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="py-2">
        <FieldArray
          name="members"
          render={() => (
            <>
              {(values?.members)?.map((item: TScienceCouncilMember, index: number) => (
                <div
                  className={`member py-2 border`}
                  key={index}
                >
                  <Row>
                    <Col xs={12}>
                      <div className="label min-w-150px fw-bold text-pri spaces pl-16 py-8">
                        Thành viên {index + 1}
                      </div>
                      <div className="flex align-items-center pb-4">
                        <div className="label min-w-150px fw-bold spaces pl-16">
                          Họ và tên
                        </div>
                        <div className="flex-1">
                          {isView ? (
                            <TextFieldCustom
                              type="text"
                              title=""
                              name={`members.${index}.name`}
                              disabled={isView}
                              values={values?.members?.[index]?.name}
                              errors={""}
                              handleChange={handleChange}
                            />
                          ): (
                            <Select
                              name={`member${index + 1}`}
                              value={values?.members?.[index]}
                              onChange={(option: SingleValue<TScienceCouncilMember>) => {
                                delete option?.id
                                setFieldValue(`members.${index}`, option);
                              }}
                              getOptionLabel={(option) => option?.name || ""}
                              getOptionValue={(option) => option?.id || ""}
                              options={listScienceCouncilMember}
                            />
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} className="pb-4">
                      <div className="flex align-items-center">
                        <div className="label min-w-150px fw-bold spaces pl-16">
                          Giới tính
                        </div>
                        <div className="flex-1">
                          <AutocompleteObjectV2
                            options={GENDER}
                            isSearchDefauilt={true}
                            name={"genderOption"}
                            isDisabled={isView}
                            onChange={(e: any) =>  {
                              setFieldValue("gender", e?.value || null);
                              setFieldValue("genderOption", e);
                            }}
                            value={values?.members?.[index]?.genderOption || GENDER.find((item: any) => item?.value === values?.members?.[index]?.gender) || GENDER.find((item: any) => item?.value === details?.members?.[index]?.gender)}
                            errors={""}
                            searchObject={{}}
                            getOptionLabel={(e: any) => e?.title}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} className="pb-4">
                      <div className="flex align-items-center">
                        <div className="label min-w-150px fw-bold spaces pl-16">
                          Vai trò
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name={`members.${index}.duty`}
                            disabled={isView}
                            values={values?.members?.[index]?.duty }
                            errors={""}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} className="pb-4">
                      <div className="flex align-items-center">
                        <div className="label min-w-150px fw-bold spaces pl-16">
                          Học hàm
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name={`members.${index}.academicRank`}
                            disabled={isView}
                            values={values?.members?.[index]?.academicRank}
                            errors={""}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} className="pb-4">
                      <div className="flex align-items-center">
                        <div className="label min-w-150px fw-bold spaces pl-16">
                          Học vị
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name={`members.${index}.degree`}
                            disabled={isView}
                            values={values?.members?.[index]?.degree}
                            errors={""}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} className="pb-4">
                      <div className="flex align-items-center">
                        <div className="label min-w-150px fw-bold spaces pl-16">
                          Khoa/Phòng
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name={`members.${index}.department`}
                            disabled={isView}
                            values={values?.members?.[index]?.department }
                            errors={""}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} className="pb-4">
                      <div className="flex align-items-center">
                        <div className="label min-w-150px fw-bold spaces pl-16">
                          Chức vụ
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name={`members.${index}.position`}
                            disabled={isView}
                            values={values?.members?.[index]?.position }
                            errors={""}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} className="pb-4">
                      <div className="flex align-items-center">
                        <div className="label min-w-150px fw-bold spaces pl-16">
                          Email
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name={`members.${index}.email`}
                            disabled={isView}
                            values={values?.members?.[index]?.email }
                            errors={""}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} className="pb-4">
                      <div className="flex align-items-center">
                        <div className="label min-w-150px fw-bold spaces pl-16">
                          SĐT
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name={`members.${index}.phone`}
                            disabled={isView}
                            values={values?.members?.[index]?.phone }
                            errors={""}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} className="pb-4">
                      <div className="flex align-items-center">
                        <div className="label min-w-150px fw-bold spaces pl-16">
                          Đơn vị công tác
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name={`members.${index}.office`}
                            disabled={isView}
                            values={values?.members?.[index]?.office }
                            errors={""}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              ))}
            </>
          )}
        />
      </Row>
    </div>
  );
};

export { Step2 };

