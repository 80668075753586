import { columnNamesType, OctTable } from "@oceantech/oceantech-ui";
import { Form, Formik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { Col, FormCheck, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { AppContext } from "../../appContext/AppContext";
import { TextFieldCustom } from "../../category-personnel/components/text-field-custom/TextFieldCustom";
import AutocompleteObjectV2 from '../../component/AutocompleteObjectV2';
import { getTopicById } from "../../de-tai-cua-toi/services/services";
import { CODE, DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE, MEMBER_TYPE } from "../../utils/Constant";
import { initProposal, TBatchOfRegistration, TMainResearcher, TMember, TProposal } from "../models/TopicRegistrationModel";
import { addProposal, searchDepartment } from "../services/services";
import { TopicRegistrationContext } from "../TopicRegistrationContext";
import AddMemberDialog from "./AddMemberDialog";
import { DEPARTMENTS } from "../constants/constants";

type Props = {
  show: boolean;
  onHide: () => void;
  batchOfRegistrationInfo: TBatchOfRegistration;
  onRefresh: () => Promise<void>;
};

const AddProposalDialog: FC<Props> = (props) => {
  const { show, onHide, batchOfRegistrationInfo, onRefresh } = props;

  const { onReset } = useContext(TopicRegistrationContext);
  const { setIsLoading } = useContext(AppContext);

  const [openDialogAddMember, setOpenDialogAddMember] = useState(false);
  const [participatingMembers, setParticipatingMembers] = useState<TMember[]>([]);
  const [mainResearcher, setMainResearcher] = useState<TMainResearcher>({});

  useEffect(() => {
    let topicId = batchOfRegistrationInfo?.topicIdOfCurrentUser;
    if(!topicId) return;
    getTopicDetails(topicId);
  }, [])

  const getTopicDetails = async (topicId: string) => {
    try {
      setIsLoading(true);
      let { data } = await getTopicById(topicId);
      if (data?.code === CODE.SUCCESS) {
        setParticipatingMembers(
          data?.data?.members?.filter(
            (member: TMember) => member?.typeId === MEMBER_TYPE.CAN_BO_THUC_HIEN
          ) as TMember[]
        );
        setMainResearcher(data?.data?.members?.find(
          (member: TMainResearcher) => member?.typeId === MEMBER_TYPE.CHU_NHIEM_DE_TAI
        ) as TMainResearcher)
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  let validationSchema = Yup.object({});

  const handleFormSubmit = async (values: TProposal) => {
    let members = [{ ...mainResearcher, typeId: 1 }, { ...values.topicSecretary, typeId: 2 }, ...participatingMembers];
    let dataProposal = {
      name: values.name,
      urgency: values.urgency,
      objective: values.objective,
      content: values.content,
      estimatedTimeframe: values.estimatedTimeframe,
      expectedOutcome: values.expectedOutcome,
      feasibility: values.feasibility,
      otherInformation: values.otherInformation,
      members
    }
    try {
      setIsLoading(true);
      let { data } = await addProposal(dataProposal, String(batchOfRegistrationInfo.topicIdOfCurrentUser));
      if (CODE.SUCCESS === data?.code) {
        setIsLoading(false);
        toast.success("Thêm đề xuất thành công");
        onHide();
        onRefresh();
      } else {
        setIsLoading(false);
        let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
        toast.warning(errorMesage);
      }
    } catch (error) {
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      console.error(error);
    } finally {

    }
    setIsLoading(false);
  };

  // const handleDeleteMember = (member: TMember) => {
  //   let index = participatingMembers.findIndex(
  //     (item) => item.fakeId === member.fakeId
  //   );
  //   if (index !== -1) {
  //     setParticipatingMembers([
  //       ...participatingMembers.slice(0, index),
  //       ...participatingMembers.slice(index + 1),
  //     ]);
  //   }
  // }

  const memberColumns: columnNamesType[] = [
    {
      name: "STT",
      field: "stt",
      headerStyle: {
        width: "50px",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row, index, stt) => {
        return stt;
      },
    },
    {
      name: "Họ tên",
      field: "name",
      headerStyle: {
        minWidth: "150px",
      },
    },
    {
      name: "Chức vụ",
      field: "position",
      headerStyle: {
        minWidth: "200px",
      },
    },
    {
      name: "Tham gia chính",
      field: "isKeyMember",
      headerStyle: {
        minWidth: "50px",
        maxWidth: "50px"
      },
      cellStyle: {
        textAlign: "center"
      },
      render: (row, index, stt) => {
        return (
          <FormCheck checked={row.isKeyMember} readOnly onChange={() => {}}/>
        )
      }
    },
    // {
    //   name: "Thao tác",
    //   field: "actions",
    //   headerStyle: {
    //     width: "150px",
    //   },
    //   cellStyle: {
    //     textAlign: "center"
    //   },
    //   render(data, index, numericalOrder, itemList) {
    //     return (
    //       <div className="flex spaces justify-content-center">
    //         <CustomIconButton variant="delete" onClick={() => handleDeleteMember(data)}>
    //           <i className="bi bi-trash3-fill text-danger"></i>
    //         </CustomIconButton>
    //       </div>
    //     );
    //   },
    // },
  ]

  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      size="xl"
      fullscreen
      className="page-full spaces h-calc-vh-50"
    >
      <Formik<TProposal>
        enableReinitialize
        initialValues={{ ...initProposal }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleFormSubmit(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Modal.Header className="header-modal min-h-40px">
                <div className="d-flex gap-6 py-2">
                  <div
                    className="d-flex align-items-center title-back gap-3 cursor-pointer"
                    onClick={() => {
                      onReset();
                      onHide();
                    }}
                  >
                    <i className="spaces bi bi-chevron-left ml-10"> </i>
                    <div className="spaces mr-20">Quay lại</div>
                  </div>
                  <h4 className="text-title border-bottom text-pri fw-bold fs-4 spaces mb-0 px-10">
                    Thêm mới phiếu đề xuất
                  </h4>
                </div>
              </Modal.Header>
              <Modal.Body className="spaces p-0 mt-5 bg-white">
                <h4 className="text-title border-bottom text-pri fw-bold fs-4 spaces mb-0 px-10">
                  Phiếu đề xuất đề tài cấp cơ sở năm 2024
                </h4>
                <div className="form spaces p-10">
                  <>
                    <Row className="py-2">
                      <Col xs={6}>
                        <div className="flex align-items-center">
                          <div className="label min-w-100px fw-bold">
                            Tên đề tài
                          </div>
                          <div className="flex-1">
                            <TextFieldCustom
                              type="text"
                              title=""
                              name="name"
                              values={values.name}
                              errors={errors.name}
                              handleChange={handleChange}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="py-2">
                      <Col
                        xs={12}
                        className="label min-w-100px fw-bold pb-2 text-gray"
                      >
                        Chủ nhiệm đề tài/Nghiên cứu viên chính:
                      </Col>
                      <Col xs={4}>
                        <div className="flex align-items-center px-2">
                          <div className="label min-w-100px fw-bold">
                            Họ tên
                          </div>
                          <div className="flex-1">
                            <TextFieldCustom
                              type="text"
                              title=""
                              name="mainResearcher.name"
                              disabled
                              values={values.mainResearcher?.name || mainResearcher?.name}
                              errors={errors.mainResearcher}
                              handleChange={handleChange}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xs={4}>
                        <div className="flex align-items-center">
                          <div className="label min-w-100px fw-bold">
                            Khoa phòng
                          </div>
                          <div className="flex-1">
                            <TextFieldCustom
                              type="text"
                              title=""
                              disabled
                              name="mainResearcher.department"
                              values={values.mainResearcher?.department || mainResearcher?.department}
                              errors={errors.mainResearcher}
                              handleChange={handleChange}
                              // options={DEPARTMENTS}
                            />
                            {/* <AutocompleteObjectV2
                              options={[]}
                              isSearchDefauilt={true}
                              name={"mainResearcher.department"}
                              onChange={(e: any) =>  { setFieldValue("mainResearcher.department", e?.name || null) }}
                              searchFunction={() =>
                                searchDepartment({
                                  pageIndex: DEFAULT_PAGE_INDEX,
                                  pageSize: MAX_PAGE_SIZE,
                                  level: 1
                                })
                              }
                              value={values.mainResearcher?.departmentOption}
                              errors={errors.mainResearcher}
                              searchObject={{}}
                              getOptionLabel={(e: any) => e?.name}
                            /> */}
                          </div>
                        </div>
                      </Col>
                      <Col xs={4}>
                        <div className="flex align-items-center">
                          <div className="label min-w-100px fw-bold">
                            Chức vụ
                          </div>
                          <div className="flex-1">
                            <TextFieldCustom
                              type="text"
                              title=""
                              disabled
                              name="mainResearcher.position"
                              values={values.mainResearcher?.position || mainResearcher?.position}
                              errors={errors.mainResearcher}
                              handleChange={handleChange}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xs={4}>
                        <div className="flex align-items-center px-2 pt-6">
                          <div className="label min-w-100px fw-bold">
                            Điện thoại
                          </div>
                          <div className="flex-1">
                            <TextFieldCustom
                              type="text"
                              title=""
                              disabled
                              name="mainResearcher.phone"
                              values={values.mainResearcher?.phone || mainResearcher?.phone}
                              errors={errors.mainResearcher}
                              handleChange={handleChange}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xs={4}>
                        <div className="flex align-items-center pt-6">
                          <div className="label min-w-100px fw-bold">Email</div>
                          <div className="flex-1">
                            <TextFieldCustom
                              type="text"
                              title=""
                              disabled
                              name="mainResearcher.email"
                              values={values.mainResearcher?.email || mainResearcher?.email}
                              errors={errors.mainResearcher}
                              handleChange={handleChange}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="py-2">
                      <Col xs={12} className="pb-2">
                        <span className="label min-w-100px fw-bold text-gray">
                          Thư ký đề tài
                        </span>
                        <span className="fst-italic fw-light text-gray">
                          {" "}
                          (tham gia với &gt;50% thời gian, nếu có):
                        </span>
                      </Col>
                      <Col xs={4}>
                        <div className="flex align-items-center px-2">
                          <div className="label min-w-100px fw-bold">
                            Họ tên
                          </div>
                          <div className="flex-1">
                            <TextFieldCustom
                              type="text"
                              title=""
                              name="topicSecretary.name"
                              values={values.topicSecretary?.name}
                              errors={errors.topicSecretary}
                              handleChange={handleChange}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xs={4}>
                        <div className="flex align-items-center">
                          <div className="label min-w-100px fw-bold">
                            Khoa phòng
                          </div>
                          <div className="flex-1">
                            <TextFieldCustom
                              type="text"
                              title=""
                              name="topicSecretary.department"
                              values={values.topicSecretary?.department}
                              errors={errors.topicSecretary}
                              handleChange={handleChange}
                              // options={DEPARTMENTS}
                            />
                            {/* <AutocompleteObjectV2
                              options={[]}
                              isSearchDefauilt={true}
                              name={"topicSecretary.department"}
                              onChange={(e: any) =>  { setFieldValue("topicSecretary.department", e?.name || null) }}
                              searchFunction={() =>
                                searchDepartment({
                                  pageIndex: DEFAULT_PAGE_INDEX,
                                  pageSize: MAX_PAGE_SIZE,
                                  level: 1
                                })
                              }
                              value={values.topicSecretary?.departmentOption}
                              errors={errors.topicSecretary}
                              searchObject={{}}
                              getOptionLabel={(e: any) => e?.name}
                            /> */}
                          </div>
                        </div>
                      </Col>
                      <Col xs={4}>
                        <div className="flex align-items-center">
                          <div className="label min-w-100px fw-bold">
                            Chức vụ
                          </div>
                          <div className="flex-1">
                            <TextFieldCustom
                              type="text"
                              title=""
                              name="topicSecretary.position"
                              values={values.topicSecretary?.position}
                              errors={errors.topicSecretary}
                              handleChange={handleChange}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xs={4}>
                        <div className="flex align-items-center px-2 pt-6">
                          <div className="label min-w-100px fw-bold">
                            Điện thoại
                          </div>
                          <div className="flex-1">
                            <TextFieldCustom
                              type="text"
                              title=""
                              name="topicSecretary.phone"
                              values={values.topicSecretary?.phone}
                              errors={errors.topicSecretary}
                              handleChange={handleChange}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xs={4}>
                        <div className="flex align-items-center pt-6">
                          <div className="label min-w-100px fw-bold">Email</div>
                          <div className="flex-1">
                            <TextFieldCustom
                              type="text"
                              title=""
                              name="topicSecretary.email"
                              values={values.topicSecretary?.email}
                              errors={errors.topicSecretary}
                              handleChange={handleChange}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="py-2">
                      <Col
                        xs={12}
                        className="label min-w-100px fw-bold pb-2 text-gray"
                      >
                        <div className="flex justify-content-between align-items-center">
                          <p className="m-0">Thành viên tham gia</p>
                          {/* <button 
                            className="spaces min-w-90px btn btn-primary"
                            onClick={() => setOpenDialogAddMember(true)}
                            type="button"
                          >Thêm thành viên</button> */}
                        </div>
                      </Col>
                      <Col xs={12} className="member-table">
                        <OctTable
                          id="dx-ds-thanh-vien"
                          className="spaces thanh-vien-table"
                          columns={memberColumns}
                          data={participatingMembers}
                          justFilter={false}
                          clearToolbar
                          noToolbar
                          noPagination
                        />
                      </Col>
                    </Row>
                    <Row className="py-2">
                      <Col xs={12} className="pb-2">
                        <span className="label min-w-100px fw-bold">
                          1. Giải thích về tính cấp thiết của đề tài
                        </span>
                        <span className="fst-italic">
                          {" "}
                          (Nêu tầm quan trọng, bức xúc, cấp bách)
                        </span>
                      </Col>
                      <TextFieldCustom
                        className="min-h-90px"
                        type="text"
                        as={"textarea"}
                        title=""
                        name="urgency"
                        values={values.urgency}
                        errors={errors.urgency}
                        handleChange={handleChange}
                      />
                    </Row>
                    <Row className="py-2">
                      <Col xs={12} className="pb-2">
                        <span className="label min-w-100px fw-bold">
                          2. Mục tiêu dự kiến
                        </span>
                      </Col>
                      <TextFieldCustom
                        className="min-h-90px"
                        type="text"
                        as={"textarea"}
                        title=""
                        name="objective"
                        values={values.objective}
                        errors={errors.objective}
                        handleChange={handleChange}
                      />
                    </Row>
                    <Row className="py-2">
                      <Col xs={12} className="pb-2">
                        <span className="label min-w-100px fw-bold">
                          3. Nội dung
                        </span>
                        <span className="fst-italic">
                          {" "}
                          (Trình bày phương pháp tiếp cận, thiết kế nghiên cứu
                          và nội dung nghiên cứu)
                        </span>
                      </Col>
                      <TextFieldCustom
                        className="min-h-90px"
                        type="text"
                        as={"textarea"}
                        title=""
                        name="content"
                        values={values.content}
                        errors={errors.content}
                        handleChange={handleChange}
                      />
                    </Row>
                    <Row className="py-2">
                      <Col xs={12} className="pb-2">
                        <span className="label min-w-100px fw-bold">
                          4. Ước tính thời gian nghiên cứu
                        </span>
                      </Col>
                      <TextFieldCustom
                        className="min-h-90px"
                        type="text"
                        as={"textarea"}
                        title=""
                        name="estimatedTimeframe"
                        values={values.estimatedTimeframe}
                        errors={errors.estimatedTimeframe}
                        handleChange={handleChange}
                      />
                    </Row>
                    <Row className="py-2">
                      <Col xs={12} className="pb-2">
                        <span className="label min-w-100px fw-bold">
                          5. Dự kiến sản phẩm
                        </span>
                        <span className="fst-italic">
                          {" "}
                          (Quy trình/bài báo/đào tạo)
                        </span>
                      </Col>
                      <TextFieldCustom
                        className="min-h-90px"
                        type="text"
                        as={"textarea"}
                        title=""
                        name="expectedOutcome"
                        values={values.expectedOutcome}
                        errors={errors.expectedOutcome}
                        handleChange={handleChange}
                      />
                    </Row>
                    <Row className="py-2">
                      <Col xs={12} className="pb-2">
                        <span className="label min-w-100px fw-bold">
                          6. Khả năng ứng dụng vào Trung tâm/khoa phòng/bệnh
                          viện
                        </span>
                      </Col>
                      <TextFieldCustom
                        className="min-h-90px"
                        type="text"
                        as={"textarea"}
                        title=""
                        name="feasibility"
                        values={values.feasibility}
                        errors={errors.feasibility}
                        handleChange={handleChange}
                      />
                    </Row>
                    <Row className="py-2">
                      <Col xs={12} className="pb-2">
                        <span className="label min-w-100px fw-bold">
                          7. Thông tin khác
                        </span>
                        <span className="fst-italic">
                          {" "}
                          (nếu có, đề tài dựa vào chương trình đề tài khác)
                        </span>
                      </Col>
                      <TextFieldCustom
                        className="min-h-90px"
                        type="text"
                        as={"textarea"}
                        title=""
                        name="otherInformation"
                        values={values.otherInformation}
                        errors={errors.otherInformation}
                        handleChange={handleChange}
                      />
                    </Row>
                  </>
                </div>
              </Modal.Body>
              <Modal.Footer className="flex justify-content-end">
                {/* <div
                  className="d-flex align-items-center title-back gap-3 cursor-pointer"
                  onClick={() => {}}
                >
                  <i className="spaces bi bi-file-pdf ml-10"> </i>
                  <div className="spaces mr-20 my-3">Xuất file PDF</div>
                </div>
                <div
                  className="d-flex align-items-center title-back gap-3 cursor-pointer"
                  onClick={() => {}}
                >
                  <i className="spaces bi bi-printer ml-10"> </i>
                  <div className="spaces mr-20 my-3">In phiếu</div>
                </div> */}
                <button
                  type="submit"
                  className="spaces min-w-90px btn btn-primary btn btn-primary"
                >
                  Lưu
                </button>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
      {openDialogAddMember && (
        <AddMemberDialog
          show={openDialogAddMember}
          onHide={() => setOpenDialogAddMember(false)}
          onAddMembers={(members: TMember[]) => setParticipatingMembers([...participatingMembers, ...members])}
        />
      )}
    </Modal>
  );
};
export default AddProposalDialog;
