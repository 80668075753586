import { columnNamesType, OctTable } from "@oceantech/oceantech-ui";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import { FC, useContext, useEffect, useState } from "react";
import { Col, Form as FormBS, Modal, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { AppContext } from "../../../appContext/AppContext";
import { TextFieldCustom } from "../../../category-personnel/components/text-field-custom/TextFieldCustom";
import { CODE, GENDER, MEMBER_TYPE, ORG_TYPE } from "../../../utils/Constant";
import {
  initTopicOutline,
  TExecutiveOrganization,
  TImplementOfficer,
  TMainCoordinatingOrganization,
  TMainResearcher,
  TMyTopic,
  TTopicOutline,
  TTopicSecretary
} from "../../models/MyTopicModel";
import { addTopicOutline, getTopicById } from "../../services/services";
import AddOfficerDialog from "../AddOfficerDialog";
import AddOrganizationDialog from "../AddOrganizationDialog";
import CustomIconButton from "../../../component/custom-icon-button/CustomIconButton";
import { TTopicDetails } from "../../../quan-ly-de-tai/de-tai/models/TopicModel";
import { objectToFormData } from "../../../utils/AppFunction";

type IProps = {
  topicInfo: TTopicDetails;
  executiveOrganization: TExecutiveOrganization;
  mainResearcher: TMainResearcher;
  topicSecretary: TTopicSecretary;
  implementOfficerList: TImplementOfficer[];
  mainCoordinatingOrganizationList: TMainCoordinatingOrganization[];
  formRef: React.MutableRefObject<any>;
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
}
const TabOutline: FC<IProps> = (props) => {
  const { topicInfo, executiveOrganization, mainResearcher, topicSecretary, implementOfficerList, mainCoordinatingOrganizationList, formRef, isEdit, setIsEdit } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const { setIsLoading } = useContext(AppContext);
  const state = location.state as { show?: boolean; data?: TMyTopic };

  const [openAddOfficer, setOpenAddOfficer] = useState(false);
  const [officerList, setOfficerList] = useState<TImplementOfficer[]>([]);
  const [openAddOrganization, setOpenAddOrganization] = useState(false);
  const [organizationList, setOrganizationList] = useState<TMainCoordinatingOrganization[]>([]);
  const [dataOrg, setDataOrg] = useState<TMainCoordinatingOrganization>({});
  const [dataOfficer, setDataOfficer] = useState<TImplementOfficer>({});

  let validationSchema = Yup.object({});

  useEffect(() => {
    setOrganizationList(mainCoordinatingOrganizationList);
    setOfficerList(implementOfficerList);
  }, [mainCoordinatingOrganizationList, implementOfficerList]);

  const onAddOfficer = (officer: TImplementOfficer) => {
    let index = officerList.findIndex(
      (item) => item.fakeId === officer.fakeId
    );
    if (index !== -1) {
      setOfficerList([
        ...officerList.slice(0, index),
        officer,
        ...officerList.slice(index + 1),
      ]);
    } else {
      setOfficerList([...officerList, officer]);
    }
  }

  const onAddOrganization = (organization: TMainCoordinatingOrganization) => {
    let index = organizationList.findIndex(
      (item) => item.fakeId === organization.fakeId
    );
    if (index !== -1) {
      setOrganizationList([
        ...organizationList.slice(0, index),
        organization,
        ...organizationList.slice(index + 1),
      ]);
    } else {
      setOrganizationList([...organizationList, organization]);
    }
  }

  const handleEditOgnization = (organization: TMainCoordinatingOrganization) => {
    setDataOrg(organization);
    setOpenAddOrganization(true);
  }

  const handleDeleteOgnization = (organization: TMainCoordinatingOrganization) => {
    let index = -1
    if(organization.id){
      index = organizationList.findIndex(
        (item) => item.id === organization.id
      );
    }else if(organization.fakeId){
      index = organizationList.findIndex(
        (item) => item.fakeId === organization.fakeId
      );
    }else{
      return;
    }
    if (index !== -1) {
      setOrganizationList([
        ...organizationList.slice(0, index),
        ...organizationList.slice(index + 1),
      ]);
    }
  }

  const handleEditOfficer = (officer: TImplementOfficer) => {
    setDataOfficer(officer);
    setOpenAddOfficer(true);
  }

  const handleDeleteOfficer = (officer: TImplementOfficer) => {
    let index = -1
    if(officer.id){
      index = officerList.findIndex(
        (item) => item.id === officer.id
      );
    }else if(officer.fakeId){
      index = officerList.findIndex(
        (item) => item.fakeId === officer.fakeId
      );
    }else{
      return;
    }
    if (index !== -1) {
      setOfficerList([
        ...officerList.slice(0, index),
        ...officerList.slice(index + 1),
      ]);
    }
  }
  const handleFormSubmit = async (values: TTopicOutline) => {
    const newOfficerList = officerList.map((officer, index) => {
      return {
        ...officer,
        scientificTitle: officer.scientificTitle,
        officeCurrently: officer.officeCurrently,
        typeId: officer.typeId,
        academicRank: officer.academicRank,
        degree: officer.degree,
        name: officer.name
      }
    })
    let dataTopicOutline = {
      name: values.name || "",
      code: values.code || "",
      numMonths: (values.startDate && values.endDate) ? (moment(values.endDate).month() - moment(values.startDate).month()) + 1 : 0,
      startDate: values.startDate ? moment(values.startDate).startOf("month").format("YYYY-MM-DD") : "",
      endDate: values.endDate ? moment(values.endDate).endOf("month").format("YYYY-MM-DD") : "",
      manageLevel: values.manageLevel || "",
      budgetTotal: (values.budgetFromGovernment || 0) + (values.budgetFromOther || 0),
      budgetFromGovernment: values.budgetFromGovernment || 0,
      budgetFromOther: values.budgetFromOther || 0,
      isPaymentEntire: values.isPaymentEntire || false,
      isPaymentPart: values.isPaymentPart || false,
      paymentPieceWork: values.paymentPieceWork || 0,
      paymentNotPieceWork: values.paymentNotPieceWork || 0,
      isPartProgram: values.isPartProgram || false,
      programName: values.programName || "",
      programCode: values.programCode || "",
      isScience: values.isScience || false,
      isSingle: values.isSingle || false,
      isOther: values.isOther || false,
      members: [{...mainResearcher, typeId: MEMBER_TYPE.CHU_NHIEM_DE_TAI}, {...topicSecretary, typeId: MEMBER_TYPE.THU_KY}, ...newOfficerList],
      offices: [{...values.executiveOrganization, typeId: ORG_TYPE.TO_CHUC_CHU_TRI}, ...organizationList],
    }
    let formData = objectToFormData(dataTopicOutline);
    try {
      setIsLoading(true);
      let { data } = await addTopicOutline(formData, String(state?.data?.id));
      if (CODE.SUCCESS === data?.code) {
        setIsLoading(false);
        toast.success("Chỉnh sửa đề cương thành công");
      } else {
        setIsLoading(false);
        let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
        toast.warning(errorMesage);
      }
    } catch (error) {
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      console.error(error);
    } finally {

    }
    setIsLoading(false);
  };
  const handleSingleCheckboxChange = (setFieldValue:any, checkboxName:any) => {
    setFieldValue('isPartProgram', checkboxName === 'isPartProgram');
    setFieldValue('isScience', checkboxName === 'isScience');
    setFieldValue('isSingle', checkboxName === 'isSingle');
    setFieldValue('isOther', checkboxName === 'isOther');
  };
  const officerColumns: columnNamesType[] = [
    {
      name: "STT",
      field: "stt",
      headerStyle: {
        width: "50px"
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row, index, stt) => {
        return stt;
      },
    },
    {
      name: "Họ và tên",
      field: "name",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "center",
      }
    },
    {
      name: "Học hàm",
      field: "academicRank",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "center",
      }
    },
    {
      name: "Học vị",
      field: "degree",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "center",
      }
    },
    {
      name: "Chức danh thực hiện đề tài",
      field: "scientificTitle",
      headerStyle: {
        minWidth: "200px",
      },
      cellStyle: {
        textAlign: "center",
      }
    },
    {
      name: "Tổ chức công tác",
      field: "officeCurrently",
      headerStyle: {
        minWidth: "250px",
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    // ...(isEdit ? [
    //   {
    //     name: "Thao tác",
    //     field: "actions",
    //     headerStyle: {
    //       width: "150px",
    //     },
    //     cellStyle: {
    //       textAlign: "center"
    //     },
    //     render(data: any, index: number, numericalOrder: number, itemList: any) {
    //       return (
    //         <div className="flex spaces justify-content-center">
    //           {!data?.id && (
    //             <CustomIconButton variant="edit" onClick={() => handleEditOfficer(data)}>
    //               <i className="bi bi-pencil-square text-navy"></i>
    //             </CustomIconButton>
    //           )}
    //           <CustomIconButton variant="delete" onClick={() => handleDeleteOfficer(data)}>
    //             <i className="bi bi-trash3-fill text-danger"></i>
    //           </CustomIconButton>
    //         </div>
    //       );
    //     },
    //   }
    // ] : [])
  ]

  const organizationColumns: columnNamesType[] = [
    {
      name: "STT",
      field: "stt",
      headerStyle: {
        width: "50px",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row, index, stt) => {
        return stt;
      },
    },
    {
      name: "Tên tổ chức chủ trì đề tài",
      field: "topicSupervisor",
      headerStyle: {
        minWidth: "150px",
      }
    },
    {
      name: "Điện thoại",
      field: "phone",
      headerStyle: {
        minWidth: "75px",
      },
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      name: "Fax",
      field: "fax",
      headerStyle: {
        minWidth: "75px",
        maxWidth: "75px"
      },
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      name: "Địa chỉ",
      field: "address",
      headerStyle: {
        minWidth: "250px",
        maxWidth: "250px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: "Họ và tên thủ trưởng tổ chức",
      field: "chiefName",
      headerStyle: {
        minWidth: "150px",
      }
    },
    {
      name: "Số tài khoản",
      field: "bankNumber",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      name: "Ngân hàng",
      field: "bankName",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "center"
      }
    },
    ...(isEdit ? [
      {
        name: "Thao tác",
        field: "actions",
        headerStyle: {
          width: "150px",
        },
        cellStyle: {
          textAlign: "center"
        },
        render(data: any, index: number, numericalOrder: number, itemList: any) {
          return (
            <div className="flex spaces justify-content-center">
              {(!data?.id && isEdit) && (
                <CustomIconButton variant="edit" onClick={() => handleEditOgnization(data)}>
                  <i className="bi bi-pencil-square text-navy"></i>
                </CustomIconButton>
              )}
  
              <CustomIconButton variant="delete" onClick={() => handleDeleteOgnization(data)}>
                <i className="bi bi-trash3-fill text-danger"></i>
              </CustomIconButton>
            </div>
          );
        },
      },
    ] : []),
  ]

  return (
    <>
      <Formik<TTopicDetails>
        enableReinitialize
        innerRef={formRef}
        initialValues={{ ...topicInfo, executiveOrganization, mainResearcher, topicSecretary, startDate: topicInfo?.startDate?.slice(0, -3), endDate: topicInfo?.endDate?.slice(0, -3) }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleFormSubmit(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Modal.Body className="spaces p-0 mt-5 bg-white">
                <div className="form spaces p-10">
                  <>
                    <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 pb-2">
                      1. Thông tin đề tài:
                    </h4>
                    <Row className="py-2">
                      <Col xs={4} className="pb-4">
                        <TextFieldCustom
                          type="text"
                          title="Tên đề tài"
                          name="name"
                          disabled={!isEdit}
                          values={values.name}
                          errors={errors.name}
                          handleChange={handleChange}
                        />
                      </Col>
                      <Col xs={2} className="pb-4">
                        <TextFieldCustom
                          type="text"
                          title="Mã số"
                          name="code"
                          disabled={!isEdit}
                          values={values.code}
                          errors={errors.code}
                          handleChange={handleChange}
                        />
                      </Col>
                      <Col xs={3} className="pb-4">
                        <TextFieldCustom
                          type="month"
                          title="Thực hiện từ tháng"
                          name="startDate"
                          disabled={!isEdit}
                          values={values.startDate}
                          errors={errors.startDate}
                          handleChange={handleChange}
                        />
                      </Col>
                      <Col xs={3} className="pb-4">
                        <TextFieldCustom
                          type="month"
                          title="Thực hiện đến tháng"
                          name="endDate"
                          disabled={!isEdit}
                          values={values.endDate}
                          errors={errors.endDate}
                          handleChange={handleChange}
                        />
                      </Col>
                      <Col xs={4}>
                        <TextFieldCustom
                          type="text"
                          title="Cấp quản lý"
                          name="manageLevel"
                          disabled={!isEdit}
                          values={values.manageLevel}
                          errors={errors.manageLevel}
                          handleChange={handleChange}
                        />
                      </Col>
                    </Row>
                    <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 pb-2">
                      2. Tổng kinh phí thực hiện
                      {(values.budgetFromGovernment || 0) +
                        (values.budgetFromOther || 0) >
                      0
                        ? ` ${
                            (values.budgetFromGovernment || 0) +
                            (values.budgetFromOther || 0)
                          } triệu đồng, trong đó`
                        : ""}
                      :
                    </h4>
                    <Row className="py-2">
                      <Col xs={3} className="pb-4">
                        <TextFieldCustom
                          type="number"
                          title="Từ ngân sách Nhà nước (triệu đồng)"
                          name="budgetFromGovernment"
                          disabled={!isEdit}
                          values={values.budgetFromGovernment}
                          errors={errors.budgetFromGovernment}
                          handleChange={handleChange}
                        />
                      </Col>
                      <Col xs={3} className="pb-4">
                        <TextFieldCustom
                          type="number"
                          title="Từ ngoài ngân sách Nhà nước (triệu đồng)"
                          name="budgetFromOther"
                          disabled={!isEdit}
                          values={values.budgetFromOther}
                          errors={errors.budgetFromOther}
                          handleChange={handleChange}
                        />
                      </Col>
                    </Row>
                    <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 pb-2">
                      3. Đề nghị phương thức khoán chi:
                    </h4>
                    <Row className="py-2">
                      <Col xs={12}>
                        <div className="flex align-items-center">
                          <div className="label fw-bold text-pri spaces pr-16">
                            <Field
                              type="checkbox"
                              name="isPaymentEntire"
                              disabled={!isEdit}
                              checked={values.isPaymentEntire}
                              onChange={(e: any) => {
                                handleChange(e);
                                if (e.target.checked) {
                                  setFieldValue("isPaymentPart", false);
                                  setFieldValue("paymentPieceWork", 0);
                                  setFieldValue("paymentNotPieceWork", 0);
                                }
                              }}
                            />
                          </div>
                          <div className="flex-1">
                            <FormBS.Label>
                              Khoán đến sản phẩm cuối cùng
                            </FormBS.Label>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="flex align-items-center">
                          <div className="label fw-bold text-pri spaces pr-16">
                            <Field
                              type="checkbox"
                              name="isPaymentPart"
                              disabled={!isEdit}
                              checked={values.isPaymentPart}
                              onChange={(e: any) => {
                                handleChange(e);
                                if (!e.target.checked) {
                                  setFieldValue("paymentPieceWork", 0);
                                  setFieldValue("paymentNotPieceWork", 0);
                                } else {
                                  setFieldValue(
                                    "isPaymentEntire",
                                    false
                                  );
                                }
                              }}
                            />
                          </div>
                          <div className="flex-1">
                            <FormBS.Label>
                              Khoán từng phần, trong đó
                            </FormBS.Label>
                          </div>
                        </div>
                      </Col>
                      {values.isPaymentPart && (
                        <Row className="py-2">
                          <Col xs={3}>
                            <TextFieldCustom
                              type="number"
                              title="Kinh phí khoán (triệu đồng)"
                              name="paymentPieceWork"
                              disabled={!isEdit}
                              values={values.paymentPieceWork}
                              errors={errors.paymentPieceWork}
                              handleChange={handleChange}
                            />
                          </Col>
                          <Col xs={3}>
                            <TextFieldCustom
                              type="number"
                              title="Kinh phí không khoán (triệu đồng)"
                              name="paymentNotPieceWork"
                              disabled={!isEdit}
                              values={values.paymentNotPieceWork}
                              errors={errors.paymentNotPieceWork}
                              handleChange={handleChange}
                            />
                          </Col>
                        </Row>
                      )}
                    </Row>
                    <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 pb-2">
                      4. Sự phụ thuộc:
                    </h4>
                    <Row className="py-2">
                      <Col xs={12}>
                        <div className="flex align-items-center">
                          <div className="label fw-bold text-pri spaces pr-16">
                            <Field
                              type="checkbox"
                              name="isPartProgram"
                              disabled={!isEdit}
                              checked={values.isPartProgram}
                              onChange={(e:any)=>{
                                handleChange(e)
                                handleSingleCheckboxChange(setFieldValue, 'isPartProgram');
                                
                                }}
                            />
                          </div>
                          <div className="flex-1">
                            <FormBS.Label>Thuộc chương trình (Ghi rõ tên chương trình nếu có)</FormBS.Label>
                          </div>
                        </div>
                      </Col>
                      {values.isPartProgram && (
                        <Row className="py-2">
                          <Col xs={3}>
                            <TextFieldCustom
                              type="text"
                              title="Tên chương trình"
                              name="programName"
                              disabled={!isEdit}
                              values={values.programName}
                              errors={errors.programName}
                              handleChange={handleChange}
                            />
                          </Col>
                          <Col xs={3}>
                            <TextFieldCustom
                              type="text"
                              title="Mã chương trình"
                              name="programCode"
                              disabled={!isEdit}
                              values={values.programCode}
                              errors={errors.programCode}
                              handleChange={handleChange}
                            />
                          </Col>
                        </Row>
                      )}
                      <Col xs={12}>
                        <div className="flex align-items-center">
                          <div className="label fw-bold text-pri spaces pr-16">
                            <Field
                              type="checkbox"
                              name="isScience"
                              disabled={!isEdit}
                              checked={values.isScience}
                              onChange={(e:any)=>{
                                handleChange(e)
                                handleSingleCheckboxChange(setFieldValue, 'isScience');
                                
                                }}
                            />
                          </div>
                          <div className="flex-1">
                            <FormBS.Label>Thuộc dự án khoa học</FormBS.Label>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="flex align-items-center">
                          <div className="label fw-bold text-pri spaces pr-16">
                            <Field
                              type="checkbox"
                              name="isSingle"
                              disabled={!isEdit}
                              checked={values.isSingle}
                              onChange={(e:any)=>{
                                handleChange(e)
                                handleSingleCheckboxChange(setFieldValue, 'isSingle');
                                
                                }}
                            />
                          </div>
                          <div className="flex-1">
                            <FormBS.Label>Độc lập</FormBS.Label>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="flex align-items-center">
                          <div className="label fw-bold text-pri spaces pr-16">
                            <Field
                              type="checkbox"
                              name="isOther"
                              disabled={!isEdit}
                              checked={values.isOther}
                              onChange={(e:any)=>{
                                handleChange(e)
                                handleSingleCheckboxChange(setFieldValue, 'isOther');
                                
                                }}
                            />
                          </div>
                          <div className="flex-1">
                            <FormBS.Label>Khác</FormBS.Label>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 pb-2">
                      5. Chủ nhiệm đề tài:
                    </h4>
                    <Row className="py-2">
                      <Col xs={3} className="pb-4">
                        <TextFieldCustom
                          type="text"
                          title="Họ và tên"
                          name="mainResearcher.name"
                          disabled={!isEdit}
                          values={values.mainResearcher?.name}
                          errors={errors.mainResearcher}
                          handleChange={handleChange}
                        />
                      </Col>
                      <Col xs={3} className="pb-4">
                        <TextFieldCustom
                          type="select"
                          title="Giới tính"
                          name="mainResearcher.gender"
                          disabled={!isEdit}
                          values={values.mainResearcher?.gender}
                          errors={errors.mainResearcher}
                          handleChange={handleChange}
                          options={GENDER}
                        />
                      </Col>
                      <Col xs={3} className="pb-4">
                        <TextFieldCustom
                          type="text"
                          title="Học hàm, học vị/Trình độ chuyên môn"
                          name="mainResearcher.eduLevel"
                          disabled={!isEdit}
                          values={values.mainResearcher?.eduLevel}
                          errors={errors.mainResearcher}
                          handleChange={handleChange}
                        />
                      </Col>
                      <Col xs={3} className="pb-4">
                        <TextFieldCustom
                          type="text"
                          title="Chức danh khoa học"
                          name="mainResearcher.scientificTitle"
                          disabled={!isEdit}
                          values={values.mainResearcher?.scientificTitle}
                          errors={errors.mainResearcher}
                          handleChange={handleChange}
                        />
                      </Col>
                      <Col xs={3} className="pb-4">
                        <TextFieldCustom
                          type="text"
                          title="Chức vụ"
                          name="mainResearcher.position"
                          disabled={!isEdit}
                          values={values.mainResearcher?.position}
                          errors={errors.mainResearcher}
                          handleChange={handleChange}
                        />
                      </Col>
                      <Col xs={3} className="pb-4">
                        <TextFieldCustom
                          type="text"
                          title="Tổ chức"
                          name="mainResearcher.office"
                          disabled={!isEdit}
                          values={values.mainResearcher?.office}
                          errors={errors.mainResearcher}
                          handleChange={handleChange}
                        />
                      </Col>
                      <Col xs={3} className="pb-4">
                        <TextFieldCustom
                          type="text"
                          title="Số điện thoại"
                          name="mainResearcher.phone"
                          disabled={!isEdit}
                          values={values.mainResearcher?.phone}
                          errors={errors.mainResearcher}
                          handleChange={handleChange}
                        />
                      </Col>
                      <Col xs={3} className="pb-4">
                        <TextFieldCustom
                          type="text"
                          title="Fax"
                          name="mainResearcher.fax"
                          disabled={!isEdit}
                          values={values.mainResearcher?.fax}
                          errors={errors.mainResearcher}
                          handleChange={handleChange}
                        />
                      </Col>
                      <Col xs={3}>
                        <TextFieldCustom
                          type="text"
                          title="Email"
                          name="mainResearcher.email"
                          disabled={!isEdit}
                          values={values.mainResearcher?.email}
                          errors={errors.mainResearcher}
                          handleChange={handleChange}
                        />
                      </Col>
                      <Col xs={3}>
                        <TextFieldCustom
                          type="text"
                          title="Tên tổ chức đang công tác"
                          name="mainResearcher.officeCurrently"
                          disabled={!isEdit}
                          values={values.mainResearcher?.officeCurrently}
                          errors={errors.mainResearcher}
                          handleChange={handleChange}
                        />
                      </Col>
                      <Col xs={6}>
                        <TextFieldCustom
                          type="text"
                          title="Địa chỉ tổ chức"
                          name="mainResearcher.officeAddress"
                          disabled={!isEdit}
                          values={values.mainResearcher?.officeAddress}
                          errors={errors.mainResearcher}
                          handleChange={handleChange}
                        />
                      </Col>
                    </Row>
                    <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 pb-2">
                      6. Thư ký khoa học của đề tài:
                    </h4>
                    <Row className="py-2">
                      <Col xs={3} className="pb-4">
                        <TextFieldCustom
                          type="text"
                          title="Họ và tên"
                          name="topicSecretary.name"
                          disabled={!isEdit}
                          values={values.topicSecretary?.name}
                          errors={errors.topicSecretary}
                          handleChange={handleChange}
                        />
                      </Col>
                      <Col xs={3} className="pb-4">
                        <TextFieldCustom
                          type="select"
                          title="Giới tính"
                          name="topicSecretary.gender"
                          disabled={!isEdit}
                          values={values.topicSecretary?.gender}
                          errors={errors.topicSecretary}
                          handleChange={handleChange}
                          options={GENDER}
                        />
                      </Col>
                      <Col xs={3} className="pb-4">
                        <TextFieldCustom
                          type="text"
                          title="Học hàm, học vị/Trình độ chuyên môn"
                          name="topicSecretary.eduLevel"
                          disabled={!isEdit}
                          values={values.topicSecretary?.eduLevel}
                          errors={errors.topicSecretary}
                          handleChange={handleChange}
                        />
                      </Col>
                      <Col xs={3} className="pb-4">
                        <TextFieldCustom
                          type="text"
                          title="Chức danh khoa học"
                          name="topicSecretary.scientificTitle"
                          disabled={!isEdit}
                          values={values.topicSecretary?.scientificTitle}
                          errors={errors.topicSecretary}
                          handleChange={handleChange}
                        />
                      </Col>
                      <Col xs={3} className="pb-4">
                        <TextFieldCustom
                          type="text"
                          title="Chức vụ"
                          name="topicSecretary.position"
                          disabled={!isEdit}
                          values={values.topicSecretary?.position}
                          errors={errors.topicSecretary}
                          handleChange={handleChange}
                        />
                      </Col>
                      <Col xs={3} className="pb-4">
                        <TextFieldCustom
                          type="text"
                          title="Tổ chức"
                          name="topicSecretary.office"
                          disabled={!isEdit}
                          values={values.topicSecretary?.office}
                          errors={errors.topicSecretary}
                          handleChange={handleChange}
                        />
                      </Col>
                      <Col xs={3} className="pb-4">
                        <TextFieldCustom
                          type="text"
                          title="Số điện thoại"
                          name="topicSecretary.phone"
                          disabled={!isEdit}
                          values={values.topicSecretary?.phone}
                          errors={errors.topicSecretary}
                          handleChange={handleChange}
                        />
                      </Col>
                      <Col xs={3} className="pb-4">
                        <TextFieldCustom
                          type="text"
                          title="Fax"
                          name="topicSecretary.fax"
                          disabled={!isEdit}
                          values={values.topicSecretary?.fax}
                          errors={errors.topicSecretary}
                          handleChange={handleChange}
                        />
                      </Col>
                      <Col xs={3}>
                        <TextFieldCustom
                          type="text"
                          title="Email"
                          name="topicSecretary.email"
                          disabled={!isEdit}
                          values={values.topicSecretary?.email}
                          errors={errors.topicSecretary}
                          handleChange={handleChange}
                        />
                      </Col>
                      <Col xs={3}>
                        <TextFieldCustom
                          type="text"
                          title="Tên tổ chức đang công tác"
                          name="topicSecretary.officeCurrently"
                          disabled={!isEdit}
                          values={values.topicSecretary?.officeCurrently}
                          errors={errors.topicSecretary}
                          handleChange={handleChange}
                        />
                      </Col>
                      <Col xs={6}>
                        <TextFieldCustom
                          type="text"
                          title="Địa chỉ tổ chức"
                          name="topicSecretary.officeAddress"
                          disabled={!isEdit}
                          values={values.topicSecretary?.officeAddress}
                          errors={errors.topicSecretary}
                          handleChange={handleChange}
                        />
                      </Col>
                    </Row>
                    <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 pb-2">
                      7. Tổ chức chủ trì đề tài:
                    </h4>
                    <Row className="py-2">
                      <Col xs={3} className="pb-4">
                        <TextFieldCustom
                          type="text"
                          title="Tên tổ chức chủ trì đề tài"
                          name="executiveOrganization.name"
                          disabled={!isEdit}
                          values={values.executiveOrganization?.name}
                          errors={errors.executiveOrganization}
                          handleChange={handleChange}
                        />
                      </Col>
                      <Col xs={3} className="pb-4">
                        <TextFieldCustom
                          type="text"
                          title="Điện thoại"
                          name="executiveOrganization.phone"
                          disabled={!isEdit}
                          values={values.executiveOrganization?.phone}
                          errors={errors.executiveOrganization}
                          handleChange={handleChange}
                        />
                      </Col>
                      <Col xs={3} className="pb-4">
                        <TextFieldCustom
                          type="text"
                          title="Fax"
                          name="executiveOrganization.fax"
                          disabled={!isEdit}
                          values={values.executiveOrganization?.fax}
                          errors={errors.executiveOrganization}
                          handleChange={handleChange}
                        />
                      </Col>
                      <Col xs={3} className="pb-4">
                        <TextFieldCustom
                          type="text"
                          title="Website"
                          name="executiveOrganization.website"
                          disabled={!isEdit}
                          values={values.executiveOrganization?.website}
                          errors={errors.executiveOrganization}
                          handleChange={handleChange}
                        />
                      </Col>
                      <Col xs={6} className="pb-4">
                        <TextFieldCustom
                          type="text"
                          title="Địa chỉ"
                          name="executiveOrganization.address"
                          disabled={!isEdit}
                          values={values.executiveOrganization?.address}
                          errors={errors.executiveOrganization}
                          handleChange={handleChange}
                        />
                      </Col>
                      <Col xs={3} className="pb-4">
                        <TextFieldCustom
                          type="text"
                          title="Họ và tên thủ trưởng tổ chức"
                          name="executiveOrganization.chiefName"
                          disabled={!isEdit}
                          values={values.executiveOrganization?.chiefName}
                          errors={errors.executiveOrganization}
                          handleChange={handleChange}
                        />
                      </Col>
                      <Col xs={3} className="pb-4">
                        <TextFieldCustom
                          type="text"
                          title="Số tài khoản"
                          name="executiveOrganization.bankNumber"
                          disabled={!isEdit}
                          values={values.executiveOrganization?.bankNumber}
                          errors={errors.executiveOrganization}
                          handleChange={handleChange}
                        />
                      </Col>
                      <Col xs={3} className="pb-4">
                        <TextFieldCustom
                          type="text"
                          title="Kho bạc nhà nước/Ngân hàng"
                          name="executiveOrganization.bankName"
                          disabled={!isEdit}
                          values={values.executiveOrganization?.bankName}
                          errors={errors.executiveOrganization}
                          handleChange={handleChange}
                        />
                      </Col>
                      <Col xs={3} className="pb-4">
                        <TextFieldCustom
                          type="text"
                          title="Tên cơ quan chủ quản đề tài"
                          name="executiveOrganization.topicSupervisor"
                          disabled={!isEdit}
                          values={values.executiveOrganization?.topicSupervisor}
                          errors={errors.executiveOrganization}
                          handleChange={handleChange}
                        />
                      </Col>
                    </Row>
                    <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 pb-2">
                      8. Các tổ chức phối hợp chính thực hiện đề tài (nếu có):
                    </h4>
                    <Row className="py-2">
                      <Col
                        xs={12}
                        className="label min-w-100px fw-bold pb-2 text-gray"
                      >
                        <div className="flex justify-content-between align-items-center">
                          <p className="m-0">Danh sách tổ chức</p>
                          {isEdit && (
                            <button
                              className="spaces min-w-90px btn btn-primary"
                              onClick={() => {
                                setDataOrg({});
                                setOpenAddOrganization(true);
                              }}
                              type="button"
                            >
                              Thêm tổ chức
                            </button>
                          )}
                        </div>
                      </Col>
                      <Col xs={12}>
                        <OctTable
                          id="outline-tab-organization"
                          columns={organizationColumns}
                          data={organizationList}
                          justFilter={false}
                          clearToolbar
                          noToolbar
                          noPagination
                        />
                      </Col>
                    </Row>
                    <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 pb-2">
                      9. Cán bộ thực hiện đề tài:
                    </h4>
                    <Row className="py-2">
                      <Col
                        xs={12}
                        className="label min-w-100px fw-bold pb-2 text-gray"
                      >
                        <div className="flex justify-content-between align-items-center">
                          <p className="m-0">Danh sách cán bộ</p>
                        </div>
                      </Col>
                      <Col xs={12}>
                        <OctTable
                          id="officer-organization"
                          columns={officerColumns}
                          data={officerList}
                          justFilter={false}
                          clearToolbar
                          noToolbar
                          noPagination
                        />
                      </Col>
                    </Row>
                  </>
                </div>
              </Modal.Body>
            </Form>
          );
        }}
      </Formik>
      {openAddOfficer && (
        <AddOfficerDialog
          show={openAddOfficer}
          onHide={() => setOpenAddOfficer(false)}
          data={dataOfficer}
          onAddOfficer={(officer: TImplementOfficer) => {
            onAddOfficer(officer);
          }}
        />
      )}
      {openAddOrganization && (
        <AddOrganizationDialog
          show={openAddOrganization}
          onHide={() => setOpenAddOrganization(false)}
          topicId={state.data?.id}
          data={dataOrg}
          onAddOrganization={(organization: TMainCoordinatingOrganization) => {
            onAddOrganization(organization);
          }}
        />
      )}
    </>
  );
};
export default TabOutline;
